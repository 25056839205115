import React, { Component } from "react";
import { Label, InputGroup, InputGroupText, InputGroupAddon } from "reactstrap";
import classNames from "classnames";
import onClickOutside from "react-onclickoutside";
import { TextField } from "../Styled";
import { CloseIcon } from "../../constants/icons";

class VendorDropdown extends Component {
  handleClickOutside = (e) => {
    return this.props.click(e);
  };

  render() {
    const {
      show,
      handleChangeVendor,
      selectedVendors,
      data,
    } = this.props;
    return (
      <div className={classNames("vendorDropdown", { open: show })}>
        <div className="topHeader">
          <InputGroup className="searchBox">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src="images/search.svg" alt="search" height="18" />
              </InputGroupText>
            </InputGroupAddon>
            <TextField
              className="search sm"
              type="text"
              name="search"
              placeholder="Vendor Name"
              onChange={(e) => this.props.searchVendor(e.target.value)}
            />
          </InputGroup>
          <ul className="alphabateList">
            <li
              className={classNames({ active: this.props.alphabate === "#" })}
              onClick={() => this.props.selectAlphabate("#")}
            >
              #
            </li>
            {this.props.allData.map((vendor, index) => {
              return (
                <li
                  key={index}
                  className={classNames({
                    active: this.props.alphabate === vendor.letter,
                  })}
                  onClick={() => this.props.selectAlphabate(vendor.letter)}
                >
                  {vendor.letter}
                </li>
              );
            })}
          </ul>
          <div className="close" onClick={this.props.click}>
            <CloseIcon />
          </div>
        </div>
        <ul className={classNames("vendorList scrollbar", { open: show })}>
          {data.map((vendor, index) => {
            return (
              <React.Fragment key={index}>
                <li>
                  <h5>{vendor.letter}</h5>
                </li>
                {vendor.records.map((item) => {
                  return (
                    <li key={item.id}>
                      <Label className="customCheckbox sm m-0 pr-0">
                        <input
                          type="checkbox"
                          name="vendors"
                          value={item.id}
                          onChange={(e) => handleChangeVendor(e)}
                          checked={
                            selectedVendors.indexOf(item.id) !== -1
                              ? true
                              : false
                          }
                        />
                        <span className="span-text">{item.name}</span>
                      </Label>
                    </li>
                  );
                })}
              </React.Fragment>
            );
          })}
          {!data.length ? (
            <li>
              <span>No records found.</span>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
    );
  }
}

export default onClickOutside(VendorDropdown);
