import React from "react";
import { Button } from "reactstrap";
import classNames from "classnames";
import { ImportIcon } from "../../../constants/icons";

const ImportFromHistory = (props) => {
  return (
    <Button
      className={classNames("btn-block text-center importBtn")}
      onClick={props.importFromHistoryModalOpen}
    >
      <React.Fragment>
        <ImportIcon className="mr-2" />
        Import BOM From History
      </React.Fragment>
    </Button>
  );
};

export default ImportFromHistory;
