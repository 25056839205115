import React, { useState, useEffect } from "react";
import { TextGroup, TextLabel, TextField, Button, InputGroup, InputGroupAppend, InputGroupText } from "../../Styled";
import { Input, Form, Row, Col, Label } from "reactstrap";
import classnames from "classnames";
import "./userStyle.css";
import { CloseIcon } from "../../../constants/icons";

const AddUserForm = (props) => {
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState({});
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const togglePasswordShow = () => {
    setPasswordShow(prev => !prev);
  }

  const toggleConfirmPasswordShow = () => {
    setConfirmPasswordShow(prev => !prev);
  }

  useEffect(() => {
    setErrors(props.errors);
    setRoles(props.roles);
  }, [props]);

  return (
    <div className="modalContent">
      <div className="modalHeader">
        <h4 className="modalTitle">Add New Member</h4>
        <p>All fields are required</p>
        <span className="closeIcon" onClick={() => props.close(false)}>
          <CloseIcon />
        </span>
      </div>
      <Form className="w-100 white-form" onSubmit={props.submit}>
        {/* <Label className="fileUpload">
          <div className="avtarFile">
            <img src="./images/avtar.svg" alt="avtar" />
          </div>
          <Input type="file" name="avtar" accept="image/*" />
          <div className="avtarText">
            <h5>Add Profile Picture</h5>
            <p>Image dimension (Minimum 200x200px, Less than 1 MB)</p>
          </div>
        </Label> */}
        <TextGroup>
          <TextLabel>Title</TextLabel>
          <Row>
            <Col>
              <Label key={0} className="customRadio d-inline-block">
                <Input
                  type="radio"
                  name="title"
                  value='Mr.'
                  checked={
                    'Mr.' === props.fillData.title
                      ? true
                      : false
                  }
                  onChange={props.change}
                />
                <span className="span-text">Mr.</span>
              </Label>
              <Label key={1} className="customRadio d-inline-block">
                <Input
                  type="radio"
                  name="title"
                  value='Mrs.'
                  checked={
                    'Mrs.' === props.fillData.title
                      ? true
                      : false
                  }
                  onChange={props.change}
                />
                <span className="span-text">Mrs.</span>
              </Label>
              <Label key={2} className="customRadio d-inline-block">
                <Input
                  type="radio"
                  name="title"
                  value='Ms.'
                  checked={
                    'Ms.' === props.fillData.title
                      ? true
                      : false
                  }
                  onChange={props.change}
                />
                <span className="span-text">Ms.</span>
              </Label>
            </Col>
          </Row>
          {errors.title ? (
            <span className="error-text">{errors.title}</span>
          ) : null}
        </TextGroup>
        <Row>
          <Col>
            <TextGroup className={classnames({ error: errors.first_name })}>
              <TextLabel>First Name {Object.keys(props?.requiredField)?.includes('first_name') ? ' *' : ''}</TextLabel>
              <TextField
                type="text"
                name="first_name"
                placeholder="First Name"
                value={props.fillData.first_name || ""}
                onChange={props.change}
              />
              {errors.first_name ? (
                <span className="error-text">{errors.first_name}</span>
              ) : null}
            </TextGroup>
          </Col>
          <Col>
            <TextGroup className={classnames({ error: errors.last_name })}>
              <TextLabel>Last Name {Object.keys(props?.requiredField)?.includes('last_name') ? ' *' : ''}</TextLabel>
              <TextField
                type="text"
                name="last_name"
                placeholder="Last Name"
                value={props.fillData.last_name || ""}
                onChange={props.change}
              />
              {errors.last_name ? (
                <span className="error-text">{errors.last_name}</span>
              ) : null}
            </TextGroup>
          </Col>
        </Row>
        <TextGroup className={classnames({ error: errors.email })}>
          <TextLabel>Email Address {Object.keys(props?.requiredField)?.includes('email') ? ' *' : ''}</TextLabel>
          <TextField
            type="email"
            name="email"
            placeholder="Enter your e-mail"
            value={props.fillData.email || ""}
            onChange={props.change}
          />
          {errors.email ? (
            <span className="error-text">{errors.email}</span>
          ) : null}
        </TextGroup>
        <TextGroup className={classnames({ error: errors.phone_number })}>
          <TextLabel>Phone Number {Object.keys(props?.requiredField)?.includes('phone_number') ? ' *' : ''}</TextLabel>
          <TextField
            type="text"
            name="phone_number"
            placeholder="Phone number"
            value={props.fillData.phone_number || ""}
            onChange={props.change}
          />
          {errors.phone_number ? (
            <span className="error-text">{errors.phone_number}</span>
          ) : null}
        </TextGroup>
        <TextGroup className={classnames({ error: errors.password })}>
          <TextLabel>Password {Object.keys(props?.requiredField)?.includes('password') ? ' *' : ''}</TextLabel>
          <InputGroup>
            <TextField
              type={passwordShow ? "text" : "password"}
              name="password"
              placeholder="Password"
              autoComplete="new-password"
              value={props?.fillData?.password || ""}
              onChange={props.change}
            />
            <InputGroupAppend>
              <InputGroupText onClick={() => togglePasswordShow()}>
                {passwordShow 
                ? <img
                  src="/images/eye-off.png"
                  alt="eye-off"
                  height="18"
                /> 
                : <img
                  src="/images/eye.png"
                  alt="eye"
                  height="18"
                />}
              </InputGroupText>
            </InputGroupAppend>
          </InputGroup>
          {errors.password ? (
            <span className="error-text">{errors.password}</span>
          ) : null}
        </TextGroup>
        <TextGroup className={classnames({ error: errors.confirm_password })}>
          <TextLabel>Confirm Password {Object.keys(props?.requiredField)?.includes('confirm_password') ? ' *' : ''}</TextLabel>
          <InputGroup>
            <TextField
              type={confirmPasswordShow ? "text" : "password"}
              name="confirm_password"
              placeholder="Confirm Password"
              value={props?.fillData?.confirm_password || ""}
              onChange={props.change}
            />
            <InputGroupAppend>
              <InputGroupText onClick={() => toggleConfirmPasswordShow()}>
                {confirmPasswordShow 
                ? <img
                  src="/images/eye-off.png"
                  alt="eye-off"
                  height="18"
                /> 
                : <img
                  src="/images/eye.png"
                  alt="eye"
                  height="18"
                />}
              </InputGroupText>
            </InputGroupAppend>
          </InputGroup>
          {errors.confirm_password ? (
            <span className="error-text">{errors.confirm_password}</span>
          ) : null}
        </TextGroup>
        <TextGroup>
          <TextLabel>Assign a role {Object.keys(props?.requiredField)?.includes('role') ? ' *' : ''}</TextLabel>
          <Row>
            <Col>
              {roles.map((item, index) => {
                return (
                  <Label key={index} className="customRadio d-inline-block">
                    <Input
                      type="radio"
                      name="role"
                      value={item.id}
                      checked={
                        item.id.toString() === props.fillData.role
                          ? true
                          : false
                      }
                      onChange={props.change}
                    />
                    <span className="span-text">{item.name}</span>
                  </Label>
                );
              })}
            </Col>
          </Row>
          {errors.role ? (
            <span className="error-text">{errors.role}</span>
          ) : null}
        </TextGroup>
        <TextGroup className={classnames({ error: errors.allowed_ip })}>
          <TextLabel>Allowed IPs {Object.keys(props?.requiredField)?.includes('allowed_ip') ? ' *' : ''}</TextLabel>
          <TextField
            type="text"
            name="allowed_ip"
            placeholder="Allowed IPs"
            defaultValue={props?.fillData?.allowed_ip ? props?.fillData?.allowed_ip : '*'}
            onChange={props.change}
          />
          {errors.allowed_ip ? (
            <span className="error-text">{errors.allowed_ip}</span>
          ) : null}
        </TextGroup>
        <TextGroup>
          <Button className="importBtn blue px-5 w-auto" type="submit">
            Save
          </Button>
        </TextGroup>
      </Form>
    </div>
  );
};

export default AddUserForm;
