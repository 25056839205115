import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Moment from "react-moment";
import moment from "moment";
import { FileInfo } from "../Home/HomeStyled";
import { TextLabel } from "../../Styled";
import { connect } from "react-redux";
import { TableLoader } from "../../Loader/index";
import "./BomPageBomDetailsStyle.css";

const getClassNameForColor = (argb) => {
  switch (argb) {
    case "effbf8":
    case "04b78a":
      return "bg-green";
    case "0080ff":
      return "bg-blue";
    case "ffedad":
      return "bg-yellow";
    case "fdeeee":
      return "bg-light-maroon";
    case "e9e9fc":
      return "bg-gray-opacity";
    default:
      return "";
  }
};

const getClassNameForResultObject = (font) => {
  // If title text (i.e. Insufficient Quantity), then return a specific color class.
  if (font?.color?.argb === "4b499f") {
    return "tdTitle";
  } else if (font?.color?.argb === "636e95") {
    return "tdpartsDetailsKey";
  }
  // If the font needs attention and has italic style, return italic
  else if (font?.italic) {
    return "tdItalicSubTitle";
  }
  return "";
};

const BomPageBomDetails = (props) => {
  const [bomMetadata, setBomMetadata] = useState({});
  const [bomLines, setBomLines] = useState();

  useEffect(() => {
    if (props?.bomDetails?.data) {
      setBomMetadata(props.bomDetails?.data);
    }
    if (props?.bomLines) {
      setBomLines(props.bomLines);
    }
  }, [props]);

  return (
    <div className="bomPageBomDetails">
      <div className="importBlock ">
        <div className="importBlock radius-8 bg-white">
          <FileInfo>
            <TextLabel>Customer Name</TextLabel>
            <h5>{bomMetadata.customer_name ?? "..."}</h5>
          </FileInfo>
          <FileInfo>
            <TextLabel>Date</TextLabel>
            <h5>
              {bomMetadata.date_of_upload ? (
                <Moment format="DD MMM, YYYY">
                  {moment(bomMetadata.date_of_upload, "DD/MM/YYYY").toDate()}
                </Moment>
              ) : (
                "..."
              )}
            </h5>
          </FileInfo>
          <FileInfo>
            <TextLabel>Part #REV</TextLabel>
            <h5>{bomMetadata.part_rev ?? "..."}</h5>
          </FileInfo>
          <FileInfo>
            <TextLabel>BOM Quote ID</TextLabel>
            <h5>{bomMetadata.id ?? "..."}</h5>
          </FileInfo>
          <FileInfo>
            <TextLabel>Created By</TextLabel>
            <h5>{bomMetadata.created_by ?? "..."}</h5>
          </FileInfo>
          <div className="table-responsive mh-430">
            {bomLines && bomLines.length > 0 ? (
              <Table className="table-bordered table-gray hovered mb-0 table-sticky-header">
                <thead>
                  <tr>
                    {Object.values(bomLines[0]._cells).map((item, index) => {
                      return (
                        <th
                          key={index}
                          className={getClassNameForColor(
                            item?.style?.fill?.fgColor?.argb
                          )}
                        >
                          {item._value.model.value}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="">
                  {/* eslint-disable-next-line array-callback-return */}
                  {bomLines.map((item, index) => {
                    if (index !== 0) {
                      return (
                        <tr key={index}>
                          {Object.values(item._cells).map((cell, index) => {
                            let cellValue = cell._value.model.value;
                            return (
                              <td
                                key={index}
                                className={getClassNameForColor(
                                  cell?.style?.fill?.fgColor?.argb
                                )}
                              >
                                {typeof cellValue === "object" &&
                                cellValue.richText
                                  ? Object.values(cellValue.richText).map(
                                      (item, index) => {
                                        return (
                                          <React.Fragment>
                                            <span
                                              key={index}
                                              className={getClassNameForResultObject(
                                                item.font
                                              )}
                                            >
                                              {item.text}
                                            </span>
                                            {item.text.includes("\n") ? (
                                              <br />
                                            ) : (
                                              ""
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    )
                                  : typeof cellValue === "object"
                                  ? cellValue.toString()
                                  : cell?._value?.model?.value?.toString()}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            ) : (
              <TableLoader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, {})(BomPageBomDetails);
