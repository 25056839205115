import { combineReducers } from "redux";

import user from "./user";
import column from "./column";
import columnAliases from "./columnAliases";
import customers from "./customer";
import partRevs from "./partRevs";
import importTable from "./importTable";

const rootReducer = combineReducers({
  user,
  column,
  columnAliases,
  customers,
  partRevs,
  importTable,
});

export default rootReducer;
