import {
  IMPORT_FILTER_DATA,
  UPDATE_QUOTES,
  UNSET_QUOTE,
} from "../constants/actionTypes";

const initialState = { bomlines: [], quotes: [], excelData: [] };
const importTable = (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_FILTER_DATA:
      return {
        ...action.data,
        excelData: action.data.bomlines,
        invalidQuantityColumns: action.data.invalidQuantityColumns,
      };
    case UPDATE_QUOTES:
      let updateQuote = {
        ...state,
        bomlines: action.data.bomlines,
        quotes: action.data.quotes,
      };
      return updateQuote;
    case UNSET_QUOTE:
      return initialState;
    default:
      return state;
  }
};

export default importTable;
