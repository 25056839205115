import React, { useState } from "react";
import { formValidation, toaster } from "../../../function";
import { Modal, ModalBody } from "reactstrap";
import ImportPreview from "./ImportPreview";
import SideModal from "../../Modals/SideModal";
import AddCustomer from "./AddCustomer";
import { ToastsStore } from "react-toasts";
import AddPartsReves from "./AddPartsRevs";
import FormConfirmation from "../../Modals/FormConfirmation";
import { connect } from "react-redux";

const ImportPreviewModal = (props) => {
  const {
    className,
    toggleModal,
    toggleHistoryModal,
    showBomLines,
    modal,
    columns,
    columnAliases,
    data,
    file,
    loginUser,
    sheets,
    fileUpload,
  } = props;

  const [addNew, setAddNew] = useState(false);
  const [addPartRevs, setPartRev] = useState(false);
  const [columnValue, setColumnValue] = useState([]);
  const [addFormData, setAddFormData] = useState({});
  const [errors, setErrors] = useState({});
  const requiredField = {
    name: ["required"],
  };

  const [newCustomer, setNewCustomer] = useState({});
  const [newPartRev, setNewPartRev] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [formConfirm, setFormConfirm] = useState(false);

  const requiredFieldPartsRevs = {
    name: ["required"],
    terms: ["required"],
  };
  const addNewForm = () => {
    setAddNew(!addNew);
  };
  const addNewPartRevsForm = () => {
    setPartRev(!addPartRevs);
  };
  const selectColumn = (e, index) => {
    let data = columnValue;
    data.push({ [index]: e.target.value });
    setColumnValue(data);
  };

  const initData = {
    columns: props.columns,
    selectedColumn: [],
    name: "",
    part: "",
    importBtnEnable: false,
  };

  const handlePopup = (e) => {
    if (formChanged) {
      setFormConfirm(true);
      return false;
    }
    setErrors({});
    setAddFormData({});
    addNew ? setAddNew(!addNew) : setPartRev(!addPartRevs);
  };

  const formConfirmPopup = (confirm) => {
    if (confirm) {
      setFormChanged(false);
      setErrors({});
      setAddFormData({});
      setAddNew(false);
      setPartRev(false);
    }
    return setFormConfirm(false);
  };

  const handleFormChange = (e) => {
    setErrors({});
    setFormChanged(true);
    setAddFormData({
      ...addFormData,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      ...addFormData,
    };
    let validate = formValidation(requiredField, postData);

    if (validate.formIsValid) {
      const res = await props.saveCustomer(loginUser, postData);
      if (res.code >= 200 && res.code < 400) {
        setAddFormData({});
        setAddNew(!addNew);
        setFormChanged(false);
        setNewCustomer(res.data);
        props.clearPartRev();
        setNewPartRev({});
        localStorage.setItem("selectedCustomer", JSON.stringify(res.data));
        setErrors(validate.errors);
      } else {
        props.setLoading(false);
        setErrors(res.message);
      }
    } else {
      setErrors(validate.errors);
    }
  };

  const onPartsRevsSubmit = async (e) => {
    e.preventDefault();

    const postData = {
      ...addFormData,
      customer_id: JSON.parse(localStorage.getItem("selectedCustomer")).id,
    };
    let validate = formValidation(requiredFieldPartsRevs, postData);

    if (validate.formIsValid) {
      const res = await props.savePartsRevs(loginUser, postData);
      if (res.code >= 200 && res.code < 400) {
        setAddFormData({});
        setErrors(validate.errors);
        setFormChanged(false);
        setNewPartRev(res.data);
        setPartRev(!addPartRevs);
      } else {
        setErrors(res.message);
        toaster(ToastsStore, "error", res.message);
      }
    } else {
      setErrors(validate.errors);
    }
  };

  return (
    <div>
      <Modal isOpen={modal} className={className} toggle={toggleModal}>
        <ModalBody>
          <ImportPreview
            initData={initData}
            file={file}
            fileName={file[0] ? file[0].name : ""}
            addNewForm={addNewForm}
            addNewPartRevsForm={addNewPartRevsForm}
            toggleModal={toggleModal}
            toggleHistoryModal={toggleHistoryModal}
            showBomLines={showBomLines}
            selectColumn={selectColumn}
            columns={columns}
            columnAliases={columnAliases}
            data={data}
            newCustomer={newCustomer}
            newPartRev={newPartRev}
            sheets={sheets}
            fileUpload={fileUpload}
          />
        </ModalBody>
      </Modal>
      <SideModal show={addNew} close={handlePopup}>
        <AddCustomer
          errors={errors}
          change={handleFormChange}
          fillData={addFormData}
          submit={onSubmit}
          close={handlePopup}
        />
      </SideModal>
      <SideModal show={addPartRevs} close={handlePopup}>
        <AddPartsReves
          errors={errors}
          change={handleFormChange}
          fillData={addFormData}
          customer={addFormData.name}
          submit={onPartsRevsSubmit}
          close={handlePopup}
        />
      </SideModal>
      <FormConfirmation
        show={formConfirm}
        toggle={handlePopup}
        confirm={formConfirmPopup}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapStateToDispatch = (dispatch) => ({
  clearPartRev: () => dispatch({ type: "CLEAR_PART_REV" }),
});
export default connect(mapStateToProps, mapStateToDispatch)(ImportPreviewModal);
