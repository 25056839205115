import React from "react";
import { RightModal, ModalOverlay } from "./ModalStyled";
import classnames from "classnames";

const SideModal = (props) => {
  return (
    <React.Fragment>
      <ModalOverlay
        className={classnames({ show: props.show })}
        onClick={() => props.close(false)}
      ></ModalOverlay>
      <RightModal className={classnames({ open: props.show })}>
        {props.children}
      </RightModal>
    </React.Fragment>
  );
};

export default SideModal;
