import { SET_COLUMN, UNSET_COLUMN, AUTO_QUOTE } from "../constants/actionTypes";

const column = (state = [], action) => {
  switch (action.type) {
    case SET_COLUMN:
      return action.data.data;
    case UNSET_COLUMN:
      return {};
    case AUTO_QUOTE:
      return action.data;
    default:
      return state;
  }
};

export default column;
