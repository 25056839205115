import React, { useState, useEffect } from "react";
import { TextGroup, TextLabel, TextField, Button } from "../../Styled";
import { Input, Form, Row, Col } from "reactstrap";
import classnames from "classnames";
import "./../User/userStyle.css";
import { CloseIcon } from "../../../constants/icons";

const AddCustomer = (props) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors(props.errors);
  }, [props]);

  return (
    <div className="modalContent">
      <div className="modalHeader">
        <h4 className="modalTitle">Add New Customer</h4>
        <p className="note">
          <span className="error">*</span>Required Information
        </p>
        <span className="closeIcon" onClick={() => props.close(false)}>
          <CloseIcon />
        </span>
      </div>
      <Form className="w-100 white-form" onSubmit={props.submit}>
        <Row>
          <Col>
            <TextGroup className={classnames({ error: errors.name })}>
              <TextLabel>
                Customer Name<span className="error">*</span>
              </TextLabel>
              <TextField
                type="text"
                name="name"
                placeholder="Customer Name"
                value={props.fillData.name || ""}
                onChange={props.change}
              />
              {errors.name ? (
                <span className="error-text">{errors.name}</span>
              ) : null}
            </TextGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextGroup className={classnames({ error: errors.website })}>
              <TextLabel>Website</TextLabel>
              <TextField
                type="text"
                name="website"
                placeholder="Website"
                value={props.fillData.website || ""}
                onChange={props.change}
              />
              {errors.website ? (
                <span className="error-text">{errors.website}</span>
              ) : null}
            </TextGroup>
          </Col>
        </Row>
        <TextGroup>
          <TextLabel>Email Address</TextLabel>
          <TextField
            type="email"
            name="email"
            placeholder="E-mail address"
            value={props.fillData.email || ""}
            onChange={props.change}
          />
        </TextGroup>
        <TextGroup>
          <TextLabel>Phone Number</TextLabel>
          <TextField
            type="text"
            name="phone_number"
            placeholder="(000) 000 0000"
            value={props.fillData.phone_number || ""}
            onChange={props.change}
          />
        </TextGroup>
        <TextGroup>
          <TextLabel>Address</TextLabel>
          <Input
            type="textarea"
            rows={3}
            name="address"
            value={props.fillData.address || ""}
            onChange={props.change}
          />
        </TextGroup>
        <TextGroup>
          <Button className="importBtn blue px-5 w-auto" type="submit">
            Save
          </Button>
        </TextGroup>
      </Form>
    </div>
  );
};

export default AddCustomer;
