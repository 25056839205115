import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as FileSaver from "file-saver";
import { getFile, shareFile } from "../../../actions";
import { BackButtonIcon } from "../../../constants/icons";
import { toaster } from "../../../function";
import { ToastsStore } from "react-toasts";
import DownloadButton from "../../Buttons/DownloadButton";
import EmailButton from "../../Buttons/EmailButton";

const BomPageTitle = (props) => {
  const [fileName, setFileName] = useState("");
  const [downloadMailEnabled, setDownloadMailEnabled] = useState(false);

  useEffect(() => {
    if (props?.bomDetails?.data) {
      setFileName(props.bomDetails?.data.file_name);
      setDownloadMailEnabled(true);
    }
  }, [props]);

  const downloadFile = async () => {
    const fileBlob = await props.getFile(
      props.user.token,
      props.bomDetails?.data?.file_path
    );
    const resultFileBlob = await props.getFile(
      props.user.token,
      props.bomDetails?.data?.result_file_path
    );
    if (fileBlob !== null) {
      FileSaver.saveAs(fileBlob, props.bomDetails?.data?.file_name + ".xlsx");
    }
    if (resultFileBlob !== null) {
      FileSaver.saveAs(
        resultFileBlob,
        "Result-" + props.bomDetails?.data?.file_name + ".xlsx"
      );
    }
  };

  const emailFile = async () => {
    let data = await props.shareFile(
      props.user.token,
      props.bomDetails?.data?.result_file_path,
      "Result-" + props.bomDetails?.data?.file_name + ".xlsx"
    );
    if (data.status >= 200 && data.status < 400) {
      toaster(
        ToastsStore,
        "success",
        "Sent the BOM file to your registered email."
      );
    } else {
      data = await props.shareFile(
        props.user.token,
        props.bomDetails?.data?.file_path,
        props.bomDetails?.data?.file_name + ".xlsx"
      );
      if (data.status >= 200 && data.status < 400) {
        toaster(
          ToastsStore,
          "success",
          "Sent the BOM file to your registered email."
        );
      } else if (data.status === 404) {
        toaster(ToastsStore, "error", "Could not find the file.");
      } else {
        toaster(ToastsStore, "error", "Could not get the file.");
      }
    }
  };

  return (
    <div className="bomPageTitle">
      <a href="/history">
        <BackButtonIcon className="icon" />
      </a>
      <div className="bomName">
        <div className="bomNameTitle">BOM File Name</div>
        <div className="bomNameName">{fileName}</div>
      </div>
      {downloadMailEnabled ? (
        <React.Fragment>
          <DownloadButton
            className="linkBtn download"
            onClick={() => downloadFile()}
          />
          <EmailButton className="linkBtn email" onClick={() => emailFile()} />
        </React.Fragment>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, { getFile, shareFile })(BomPageTitle);
