import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import Login from "./Auth/Login";
import Logout from "./Auth/Logout";
import ForgotPassword from "./Auth/ForgotPassword";
import "bootstrap/dist/css/bootstrap.css";
import Home from "./Pages/Home";
import User from "./Pages/User";
import Vendor from "./Pages/Vendor";
import Profile from "./Pages/Profile";
import History from "./Pages/History";
import BomPage from "./Pages/BomPage";

const App = ({ user }) => (
  <React.Fragment>
    <ToastsContainer
      position={ToastsContainerPosition.TOP_RIGHT}
      store={ToastsStore}
    />
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/forgotpassword" component={ForgotPassword} />
      {user.token ? (
        <React.Fragment>
          <Route exact path="/" component={Home} />
          <Route exact path="/user" component={User} />
          <Route exact path="/vendor" component={Vendor} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/history" component={History} />
          <Route exact path="/history/:id" component={BomPage} />
        </React.Fragment>
      ) : (
        <Redirect to="/login" />
      )}
    </Switch>
  </React.Fragment>
  // </Suspense>
);

App.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export default withRouter(connect((state) => ({ user: state.user }))(App));
