import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Layouts from "../../Layouts";
import Datatable from "../../Datatable";
import { Input, Button } from "reactstrap";
import Paginations from "../../Datatable/Paginations";
import SideModal from "../../Modals/SideModal";
import AddUserForm from "./AddUserForm";
import { getRoles, getUser, addUser, editUser } from "../../../actions";
import { connect } from "react-redux";
import { formValidation } from "../../../function";
import Moment from "react-moment";
import EditUserForm from "./EditUserForm";
import RoleConfirmation from "../../Modals/RoleConfirmation";
import FormConfirmation from "../../Modals/FormConfirmation";

const requiredField = {
  first_name: ["required"],
  last_name: ["required"],
  email: ["required", "email"],
  phone_number: ["required"],
  role: ["required"],
  password: ["required", "password"],
  confirm_password: ["required"],
  allowed_ip: ["required"],
};

const User = (props) => {
  const [searchText, setSearchText] = useState(null);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [roles, setRoles] = useState([]);
  const [userList, setUserList] = useState([]);
  const [addFormData, setAddFormData] = useState({allowed_ip: "*"});
  const [editFormData, setEditFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [roleConfirm, setRoleConfirm] = useState(false);
  const [formType, setFormType] = useState(null);
  const [confirmModelPassData, setConfirmModelPassData] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [formConfirm, setFormConfirm] = useState(false);
  const { user } = props;

  useEffect(() => {
    const getUsers = async () => {
      const res = await props.getUser(props.user.token);
      setUserList(res.data);
    };

    const getRole = async () => {
      const resRole = await props.getRoles(props.user.token);
      setRoles(resRole.data);
    };
    getRole();
    getUsers();
  }, [props]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
    setPageActive(1);
  };

  const handlePopup = (e) => {
    if (formChanged) {
      setFormConfirm(true);
      return false;
    }
    setOpenAddForm(e);
    setErrors({});
    setAddFormData({allowed_ip: "*"});
  };

  const formConfirmPopup = (confirm) => {
    if (confirm) {
      setFormChanged(false);
      setOpenAddForm(false);
      setOpenEditForm(false);
      setErrors({});
      setAddFormData({allowed_ip: "*"});
    }
    return setFormConfirm(false);
  };

  const handleEditPopup = () => {
    if (formChanged) {
      setFormConfirm(true);
      return false;
    }
    setOpenEditForm(false);
    setErrors({});
  };

  const handleFormChange = (e) => {
    if (e.target.name === "role" && e.target.value === "1") {
      setConfirmModelPassData(addFormData);
      setRoleConfirm(true);
    }
    setFormType("add");
    setFormChanged(true);
    setAddFormData({ ...addFormData, [e.target.name]: e.target.value });
  };

  const handleEditFormChange = (e) => {
    setFormType("edit");
    setFormChanged(true);
    if (e.target.name === "role_id") {
      if (e.target.value === "1") {
        setConfirmModelPassData(editFormData);
        setRoleConfirm(true);
      }
      return setEditFormData({
        ...editFormData,
        [e.target.name]: parseInt(e.target.value),
      });
    }
    return setEditFormData({
      ...editFormData,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (addFormData.email && addFormData.email.match("@trylene.com") === null) {
      return setErrors({
        email: "Invalid Email. Please use corporate email address.",
      });
    }

    if (addFormData.password && addFormData.confirm_password && addFormData.password !== addFormData.confirm_password) {
      return setErrors({
        password: "Password and Confirm Password must be same.",
        confirm_password: "Password and Confirm Password must be same.",
      });
    }

    const postData = {
      ...addFormData,
      user_name: "user_name" + userList.length + 1,
      allow_password_reset: true,
      is_active: true,
      allowed_ip: addFormData?.allowed_ip ? addFormData?.allowed_ip : ""
    };
    let validate = formValidation(requiredField, postData);

    if (validate.formIsValid) {
      const res = await props.addUser(props.user.token, postData);
      if (res.code >= 200 && res.code < 400) {
        let users = userList.concat(res.data);
        setUserList(users);
        setOpenAddForm(false);
        setAddFormData({allowed_ip: "*"});
        setErrors({});
        setFormChanged(false);
      } else {
        setErrors(res.message);
      }
    } else {
      setErrors(validate.errors);
    }
  };

  const userUpdate = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (editFormData.password && editFormData.confirm_password && editFormData.password !== editFormData.confirm_password) {
      return setErrors({
        password: "Password and Confirm Password must be same.",
        confirm_password: "Password and Confirm Password must be same.",
      });
    }
    const postUpdateData = {
      id: editFormData.id,
      first_name: editFormData.first_name,
      last_name: editFormData.last_name,
      email: editFormData.email,
      phone_number: editFormData.phone_number,
      is_active: editFormData.is_active,
      role: editFormData.role_id,
      allow_password_reset: false,
      password: editFormData.password,
      confirm_password: editFormData.confirm_password,
      title: editFormData.title,
      allowed_ip: editFormData.allowed_ip,
    };

    let validateField = { ...requiredField };
    delete validateField.email;
    if (!postUpdateData?.password) {
      delete validateField.password;
    }
    if (!postUpdateData?.confirm_password) {
      delete validateField.confirm_password;
    }

    let validate = formValidation(validateField, postUpdateData);
    if (validate.formIsValid) {
      apiCall(postUpdateData);
    } else {
      setErrors(validate.errors);
    }
  };

  const apiCall = async (apiData) => {
    const resUser = await props.editUser(props.user.token, apiData);

    if (resUser.code >= 200 && resUser.code < 400) {
      let users = userList.map((item) => {
        if (resUser.data.id === item.id) {
          return (item = resUser.data);
        }
        return item;
      });
      setUserList(users);
      setOpenEditForm(false);
      setErrors({});
      setEditFormData({});
      setFormChanged(false);
    } else {
      setErrors(resUser.message);
    }
  };

  const showUser = (user) => {
    setOpenEditForm(true);
    setEditFormData(user);
  };

  const column = [
    {
      fieldName: "first_name",
      label: "Users",
      sort: true,
      customRow: (row) => (
        <div className={classnames({ "opacity-50": !row.is_active })}>
          {/* <img src="./images/user.svg" alt="avtar" /> */}
          <span>{row.first_name + " " + row.last_name}</span>
        </div>
      ),
    },
    {
      fieldName: "last_name",
      customRow: (row) => <React.Fragment></React.Fragment>,
      dontShow: true,
    },
    {
      fieldName: "role",
      label: "Role",
      sort: true,
      customRow: (row) => (
        <span className={classnames({ "opacity-50": !row.is_active })}>
          {row.role}
        </span>
      ),
    },
    {
      fieldName: "is_active",
      label: "Status",
      sort: true,
      customRow: (row) => (
        <span className={classnames({ "opacity-50": !row.is_active })}>
          {row.is_active ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      fieldName: "created_at",
      label: "Added On",
      sort: true,
      customRow: (row) => (
        <span className={classnames({ "opacity-50": !row.is_active })}>
          <Moment format="DD/MM/YYYY">{row.created_at}</Moment>
        </span>
      ),
    },
    {
      fieldName: "",
      label: "Actions",
      width: 80,
      align: "center",
      customRow: (row) => (
        (user && user.user.role_id === 1) ? 
        <Button size="sm" color="default" onClick={() => showUser(row)}>
          <span className="fa fa-pencil"></span>
        </Button>
        : <></>
      ),
    },
  ];
  const [showEntry] = useState(10);
  const [pageActive, setPageActive] = useState(1);
  const [totalRecords, setTotalRecords] = useState(userList.length);

  const handlePagination = (e) => {
    setPageActive(e);
  };

  const changeTableParams = (total, page) => {
    if (page) setPageActive(page);
    setTotalRecords(total);
  };

  const toggleRoleConfirm = (confirm, user) => {
    setRoleConfirm(false);
    if (formType === "add") {
      setAddFormData({ ...addFormData, role: confirm ? "1" : "" });
    }
    if (formType === "edit") {
      setEditFormData({ ...editFormData, role_id: confirm ? 1 : user.role_id });
    }
  };

  return (
    <Layouts>
      <SideModal show={openAddForm} close={handlePopup}>
        <AddUserForm
          roles={roles}
          errors={errors}
          change={handleFormChange}
          fillData={addFormData}
          submit={onSubmit}
          close={handlePopup}
          requiredField={requiredField}
        />
      </SideModal>

      <SideModal show={openEditForm} close={handleEditPopup}>
        <EditUserForm
          roles={roles}
          errors={errors}
          change={handleEditFormChange}
          fillData={editFormData}
          submit={userUpdate}
          close={handleEditPopup}
          requiredField={requiredField}
          user={user}
        />
      </SideModal>

      <FormConfirmation
        show={formConfirm}
        toggle={handlePopup}
        confirm={formConfirmPopup}
      />
      <RoleConfirmation
        show={roleConfirm}
        data={confirmModelPassData}
        toggle={toggleRoleConfirm}
      />

      <div className="p-20">
        <div className="card">
          <div className="cardHeader">
            <h3>
              Users
              <small className="d-block">Total - {userList.length}</small>
            </h3>
            <div className="searchBox">
              <Input
                type="text"
                placeholder="Search..."
                name="searchText"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {user && user.user.role_id === 1 && 
              <Button className="linkBtn" onClick={() => handlePopup(true)}>
                <i className="fa fa-plus mr-2"></i>
                Add New Member
              </Button>
            }
          </div>
          <Datatable
            data={userList}
            columns={column}
            pageActive={pageActive}
            showEntry={showEntry}
            searchText={searchText}
            changeTableParams={changeTableParams}
          />
        </div>
        {totalRecords > showEntry && (
          <Paginations
            totalRecords={totalRecords}
            showEntry={showEntry}
            pageActive={pageActive}
            click={handlePagination}
            prevClick={() => setPageActive(parseInt(pageActive) - 1)}
            nextClick={() => setPageActive(parseInt(pageActive) + 1)}
          />
        )}
      </div>
    </Layouts>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, {
  getRoles,
  getUser,
  addUser,
  editUser,
})(User);
