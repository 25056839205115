import {
  GET_PARTS_REVS,
  ADD_PARTS_REVS,
  CLEAR_PART_REV,
} from "../constants/actionTypes";

const partRevs = (state = [], action) => {
  switch (action.type) {
    case GET_PARTS_REVS:
      return action.data.data;
    case ADD_PARTS_REVS:
      return state.concat(action.data);
    case CLEAR_PART_REV:
      return [];
    default:
      return state;
  }
};

export default partRevs;
