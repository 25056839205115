export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const SET_USER = "SET_USER";
export const UNSET_USER = "UNSET_USER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_TOKEN = "UPDATE_TOKEN";

export const SET_COLUMN = "SET_COLUMN";
export const UNSET_COLUMN = "UNSET_COLUMN";

export const SET_COLUMN_ALIASES = "SET_COLUMN_ALIASES";
export const UNSET_COLUMN_ALIASES = "UNSET_COLUMN_ALIASES";

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const ADD_CUSTOMERS = "ADD_CUSTOMERS";

export const IMPORT_FILTER_DATA = "IMPORT_FILTER_DATA";
export const AUTO_QUOTE = "AUTO_QUOTE";
export const UNSET_QUOTE = "UNSET_QUOTE";

export const UPDATE_QUOTES = "UPDATE_QUOTES";

export const GET_PARTS_REVS = "GET_PARTS_REVS";
export const ADD_PARTS_REVS = "ADD_PARTS_REVS";
export const CLEAR_PART_REV = "CLEAR_PART_REV";

export const UPDATE_VENDOR = "UPDATE_VENDOR";

export const AUTO_LOGOUT = 1800; // IN SECONDS
export const NOTIFY_TIME = 90; // IN SECONDS
