import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import HistoryTable from "../History/HistoryTable";

const ImportFromHistoryModal = (props) => {
  const { className, toggle, modal, user, fileUpload } = props;

  return (
    <div>
      <Modal isOpen={modal} className={className} toggle={toggle}>
        <ModalBody>
          <HistoryTable
            user={user}
            isHistoryPage={false}
            fileUpload={fileUpload}
          ></HistoryTable>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapStateToDispatch = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ImportFromHistoryModal);
