import React, { useState, useEffect } from "react";
import { TextGroup, TextLabel, TextField, Button } from "../../Styled";
import { Input, Form, Row, Col, Label } from "reactstrap";
import classnames from "classnames";
import "./../User/userStyle.css";
import { CloseIcon } from "../../../constants/icons";

const AddPartsReves = (props) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors(props.errors);
  }, [props]);
  var customer = JSON.parse(localStorage.getItem("selectedCustomer"));

  return (
    <div className="modalContent">
      <div className="modalHeader">
        <h4 className="modalTitle">Add New Part #REV</h4>
        <span className="closeIcon" onClick={() => props.close(false)}>
          <CloseIcon />
        </span>
      </div>
      <Form className="w-100 white-form" onSubmit={props.submit}>
        <TextGroup>
          <TextLabel>Customer Name</TextLabel>
          {customer && <p>{customer.name}</p>}
        </TextGroup>
        <Row>
          <Col>
            <TextGroup className={classnames({ error: errors.name })}>
              <TextLabel>
                Part Rev#<span className="error">*</span>
              </TextLabel>
              <TextField
                type="text"
                name="name"
                placeholder="Part Rev#"
                value={props.fillData.name || ""}
                onChange={props.change}
              />
              {errors.name ? (
                <span className="error-text">{errors.name}</span>
              ) : null}
            </TextGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label className="customCheckbox sm d-inline-block">
              <Input
                type="checkbox"
                name="terms"
                onChange={props.change}
                checked={props.fillData.terms ? true : false}
              />
              <span className="span-text">
                I've checked all the Part Rev# and this one does not exist
              </span>
            </Label>
            {errors.terms ? (
              <span className="error-text">Please accept terms</span>
            ) : null}
          </Col>
        </Row>
        <TextGroup>
          <Button className="importBtn blue px-5 w-auto" type="submit">
            Save
          </Button>
        </TextGroup>
      </Form>
    </div>
  );
};

export default AddPartsReves;
