import React, { useEffect, useState } from "react";
import ExcelJS from "exceljs";
import Layouts from "../../Layouts";
import BomPageTitle from "./BomPageTitle";
import BomPageQuoteTable from "./BomPageQuoteTable";
import BomPageBomDetails from "./BomPageBomDetails";
import { getFile, getBomDetails } from "../../../actions";
import { connect } from "react-redux";
import "./bomPageStyle.css";

const BomPage = (props) => {
  const [bomDetails, setBomDetails] = useState([]);
  const [bomLines, setBomLines] = useState();

  useEffect(() => {
    const getBomFile = async () => {
      const newBomDetails = await props.getBomDetails(
        props.user.token,
        props.match.params.id
      );
      setBomDetails(newBomDetails);

      let bomFilePath = newBomDetails?.data?.result_file_path
        ? newBomDetails?.data?.result_file_path
        : newBomDetails?.data?.file_path;
      if (bomFilePath) {
        const bomFile = await props.getFile(props.user.token, bomFilePath);
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(bomFile);
        const newBomLines = workbook._worksheets.find(
          (worksheet) => worksheet?._rows
        )._rows;
        setBomLines(newBomLines);
      }
    };
    getBomFile();
  }, [props]);

  return (
    <Layouts>
      <div className="p-20 bomPageBox">
        <BomPageTitle bomDetails={bomDetails}></BomPageTitle>
      </div>
      <BomPageQuoteTable bomDetails={bomDetails}></BomPageQuoteTable>
      <BomPageBomDetails
        bomDetails={bomDetails}
        bomLines={bomLines}
      ></BomPageBomDetails>
    </Layouts>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps, { getFile, getBomDetails })(BomPage);
