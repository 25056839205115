import React from "react";
import classnames from "classnames";
import { Button } from "reactstrap";
import { ModalOverlay, ModalBox } from "./ModalStyled";
import { ClockIcon } from "../../constants/icons";

const ExpireSession = (props) => {
  return (
    <ModalOverlay className={classnames({ show: props.show })}>
      <ModalBox className="smBox">
        <h2 className="modal-title text-center">
          <ClockIcon />
        </h2>
        <p className="modal-text">
          Your session has expired due to
          <br />
          inactivity. Please login again.
        </p>
        <Button className="btn-blue" block onClick={props.close}>
          OK
        </Button>
      </ModalBox>
    </ModalOverlay>
  );
};

export default ExpireSession;
