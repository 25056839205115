import React from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { CloseIcon } from "../../constants/icons";
import "./Button.css";

const NeedAttentionButton = (props) => {
  return (
    <Button
      className="bomButtons linkBtn needAttention"
      onClick={props.onClick}
    >
      <CloseIcon className="icon" />
      <div className="text">Show rows that need attention</div>
    </Button>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, {})(NeedAttentionButton);
