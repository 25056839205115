import React, { useState, useEffect } from "react";
import CreateTable from "./CreateTable";

const Datatable = (props) => {
  const [pageActive, setPageActive] = useState(1);
  const [showEntry, setShowEntry] = useState(10);
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    setPageActive(props.pageActive);
    setShowEntry(props.showEntry);
    setSearchText(props.searchText);
  }, [props]);

  return (
    <div className="table-responsive">
      <CreateTable
        records={props.data}
        pageActive={pageActive}
        showEntry={showEntry}
        columns={props.columns}
        changeTableParams={props.changeTableParams}
        searchText={searchText}
        defaultSortField={props?.defaultSortField ? props?.defaultSortField : null}
        defaultSortType={props?.defaultSortType ? props?.defaultSortType : null}
      />
    </div>
  );
};

export default Datatable;
