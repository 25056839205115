import merge from "lodash/merge";
import { ToastsStore } from "react-toasts";
import { toaster } from "../function.js";

export const post = async ({ url, body, headers, failure, dispatch }) => {
  try {
    const res = await fetch(url, {
      method: "POST",
      // headers: headers,
      headers: merge(
        {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        headers
      ),
      body: JSON.stringify(body),
    });

    const data = await res.json();
    if (data.code === 401) {
      localStorage.removeItem("state");
    }

    return data;
  } catch (e) {
    dispatch({ type: failure });
  }
};

export const get = async ({ url, success, failure, headers, dispatch }) => {
  try {
    const res = await fetch(url, {
      method: "GET",
      // headers: headers,
      headers: merge(
        {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        headers
      ),
    });

    const data = await res.json();
    if (data.code === 401) {
      localStorage.removeItem("state");
      window.location.href = "/login";
    }

    return data;
  } catch (e) {
    dispatch({ type: failure });
  }
};

export const put = async ({
  url,
  body,
  success,
  failure,
  dispatch,
  headers,
}) => {
  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: merge(
        {
          "Content-Type": "application/json",
        },
        headers
      ),
      body: JSON.stringify(body),
    });

    const data = await res.json();
    return data;
  } catch (e) {
    dispatch({ type: failure });
  }
};

export const delet = async ({
  url,
  body,
  success,
  failure,
  dispatch,
  headers,
}) => {
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: merge(
        {
          "Content-Type": "application/json",
        },
        headers
      ),
      body: JSON.stringify(body),
    });

    const data = await res.json();

    if (res.status >= 200 && res.status < 400) {
      toaster(ToastsStore, "success", data.message);
      dispatch({ type: success, data });
      return data;
    } else {
      if (data.message) toaster(ToastsStore, "error", data.message);
      dispatch({ type: failure });
    }
  } catch (e) {
    dispatch({ type: failure });
  }
};
