import React, { useState } from "react";
import classnames from "classnames";
import { TextGroup, TextLabel, TextField } from "../../Styled";
import { Button, Form, Row, Col } from "reactstrap";
import "./../User/userStyle.css";

const EditProfile = (props) => {
  const [errors] = useState({});

  return (
    <div className="card">
      <div className="cardHeader">
        <h3>Personal Information</h3>
      </div>
      <div className="cardBody pt-0">
        <Form onSubmit={props.submit}>
          {/* <Label className="fileUpload">
            <div className="avtarFile">
              <img src="./images/Avatars36px.png" alt="avtar" />
            </div>
            <Input type="file" name="avtar" accept="image/*" />
            <div className="avtarText">
              <h5>
                <b>Edit Profile Pic</b>
              </h5>
              <p>Image dimention (Minimum 200x200px, Less than 1 MB)</p>
            </div>
          </Label> */}
          <Row>
            <Col>
              <TextGroup className={classnames({ error: errors.first_name })}>
                <TextLabel>First Name</TextLabel>
                <TextField
                  type="text"
                  name="first_name"
                  placeholder="Dhruv"
                  value={props.fillData.first_name}
                  onChange={props.change}
                />
                {errors.first_name ? (
                  <span className="error-text">{errors.first_name}</span>
                ) : null}
              </TextGroup>
            </Col>
            <Col>
              <TextGroup className={classnames({ error: errors.last_name })}>
                <TextLabel>Last Name</TextLabel>
                <TextField
                  type="text"
                  name="last_name"
                  placeholder="Patel"
                  value={props.fillData.last_name}
                  onChange={props.change}
                />
                {errors.last_name ? (
                  <span className="error-text">{errors.last_name}</span>
                ) : null}
              </TextGroup>
            </Col>
          </Row>
          <TextGroup className={classnames({ error: errors.email })}>
            <TextLabel>Email Address</TextLabel>
            <p>{props.user.email}</p>
          </TextGroup>
          <TextGroup className={classnames({ error: errors.phone_number })}>
            <TextLabel>Phone Number</TextLabel>
            <TextField
              type="text"
              name="phone_number"
              placeholder="(890) 823 1212"
              value={props.fillData.phone_number || ""}
              onChange={props.change}
            />
            {errors.phone_number ? (
              <span className="error-text">{errors.phone_number}</span>
            ) : null}
          </TextGroup>
          <Row>
            <Col>
              <TextGroup className={classnames({ error: errors.email })}>
                <TextLabel>Role</TextLabel>
                <p className="fieldInfo">{props.user.role}</p>
              </TextGroup>
            </Col>
            <Col>
              <TextGroup className={classnames({ error: errors.email })}>
                <TextLabel>Status</TextLabel>
                <p className="fieldInfo">
                  {props.user.is_active === 1 ? "Active" : "Inactive"}
                </p>
              </TextGroup>
            </Col>
          </Row>
          <TextGroup>
            <Button className="btn-blue px-5 w-auto" type="submit">
              Save
            </Button>
          </TextGroup>
        </Form>
      </div>
    </div>
  );
};

export default EditProfile;
