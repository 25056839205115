import {
  SIGNUP_REQUEST,
  SET_USER,
  UNSET_USER,
  UPDATE_PROFILE,
  UPDATE_TOKEN,
} from "../constants/actionTypes";
import merge from "lodash/merge";

const user = (state = {}, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
    case SET_USER:
      let data = { ...merge(state, action.data) };
      return data;
    case UPDATE_PROFILE:
      return { ...state, user: action.data };
    case UPDATE_TOKEN:
      return { ...state, token: action.data.token };
    case UNSET_USER:
      localStorage.clear();
      return {};
    default:
      return state;
  }
};

export default user;
