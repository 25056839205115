import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Body, Overlay, Content } from "../Styled";
import {
  UNSET_USER,
  UNSET_COLUMN,
  UNSET_COLUMN_ALIASES,
  UNSET_QUOTE,
  AUTO_LOGOUT,
  NOTIFY_TIME,
} from "../../constants/actionTypes";
import SessionOut from "../Modals/SessionOut";

const Layouts = ({ user, unsetSession, children }) => {
  const checkDevice =
    window.innerWidth <= 767
      ? true
      : localStorage.getItem("collapsed")
      ? localStorage.getItem("collapsed") === "false"
        ? false
        : true
      : false;
  const [collapsed, setCollapsed] = useState(checkDevice);
  const handleCollapsed = () => {
    localStorage.setItem("collapsed", !collapsed);
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    if (sessionStorage.getItem("remember_me") === null) {
      unsetSession();
    }
  }, [unsetSession]);

  const [seconds, setSeconds] = useState(AUTO_LOGOUT);
  const [sessionModal, setSessionModal] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds <= NOTIFY_TIME) {
      setSessionModal(true);
      if (seconds <= 0) {
        localStorage.setItem("expired", "true");
        unsetSession();
      }
    }
  }, [seconds, unsetSession]);

  const availableUser = () => {
    if (!sessionModal) {
      setSeconds(AUTO_LOGOUT);
    }
  };

  const sessionToggle = () => {
    setSeconds(AUTO_LOGOUT);
    setSessionModal(false);
  };

  return (
    <Body
      className={classNames({ collapsed: collapsed })}
      onMouseMove={availableUser}
    >
      {user.token ? (
        <React.Fragment>
          <SessionOut
            show={sessionModal}
            counter={seconds}
            sessionToggle={sessionToggle}
          />

          <Header user={user} />
          <Sidebar handleClick={handleCollapsed} user={user} />
          <Content>{children}</Content>
          <Overlay />
          <Footer />
        </React.Fragment>
      ) : (
        <Redirect to="/login" />
      )}
    </Body>
  );
};

Layouts.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => {
  return {
    unsetSession: () => {
      dispatch({ type: UNSET_USER });
      dispatch({ type: UNSET_COLUMN });
      dispatch({ type: UNSET_COLUMN_ALIASES });
      dispatch({ type: UNSET_QUOTE });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Layouts);
