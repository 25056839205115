import React from "react";
import Layouts from "../../Layouts";
import { getBomHistory, getFile } from "../../../actions";
import { connect } from "react-redux";
import "./historyStyle.css";
import HistoryTable from "./HistoryTable";

const History = (props) => {
  return (
    <Layouts>
      <HistoryTable user={props.user} isHistoryPage={true}></HistoryTable>
    </Layouts>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps, { getBomHistory, getFile })(History);
