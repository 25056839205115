import React, { useState, useEffect } from "react";
import Layouts from "../../Layouts";
import Moment from "react-moment";
import classnames from "classnames";
import { Input, Button, Label, Row, Col } from "reactstrap";
import Datatable from "../../Datatable";
import Paginations from "../../Datatable/Paginations";
import { getDistributor, updateVendor } from "../../../actions";
import { connect } from "react-redux";
import { TextGroup } from "../../Styled";

const Vendor = (props) => {
  const [vendorList, setVendorList] = useState([]);
  const [newlyApprovedList, setNewlyApprovedList] = useState([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const getVendors = async () => {
      const vendors = await props.getDistributor(props.user.token);
      let approveVendors = [];
      vendors.data.forEach((item, vendorIndex) => {
        if (item.is_approved) {
          approveVendors.push({ id: item.id.toString(), is_approved: "1" });
        }
        item.vendor_array_id = vendorIndex;
        return item;
      });

      setVendorList(vendors.data);
      setShowLoading(false);
    };

    getVendors();
  }, [props, showCheckboxes]);

  const approved = (e, id, vendor_array_id) => {
    let approveData = [...newlyApprovedList];
    let vendorListData = [...vendorList];
    let index = approveData.find(
      (item) => item.id.toString() === id.toString()
    );

    if (index === undefined) {
      approveData.push({
        id: id.toString(),
        is_approved: e.target.checked ? "1" : "0",
      });
    } else {
      // As there are only two states (approved and not approved),
      // we can remove the item from newly approved list
      // if it already existed in the newly approved and the state is changed.align-items-end.
      // In such case the approval status become what it was when started.
      approveData.splice(approveData.indexOf(index), 1);
    }
    vendorListData[vendor_array_id].is_approved = e.target.checked;

    setNewlyApprovedList(approveData);
    setVendorList(vendorListData);
  };

  const column = [
    {
      fieldName: "name",
      label: "Vendor Name",
      sort: true,
      parentSelected: "is_approved",
      customRow: (row) => (
        <React.Fragment>
          {(showCheckboxes && !showLoading) ? (
            <Label className="customCheckbox sm d-inline-block">
              <Input
                type="checkbox"
                name="checkbox1"
                onChange={(e) => approved(e, row.id, row.vendor_array_id)}
                checked={row.is_approved ? true : false}
              />
              <span
                className={classnames("span-text", {
                  "opacity-50": !row.is_approved,
                })}
              >
                {row.name}
              </span>
            </Label>
          ) : (
            <span className={classnames({ "opacity-50": !row.is_approved })}>
              {row.name}
            </span>
          )}
        </React.Fragment>
      ),
    },
    {
      fieldName: "updated_by",
      label: "Updated By",
      sort: true,
      parentSelected: "is_approved",
      customRow: (row) => (
        <span className={classnames({ "opacity-50": !row.is_approved })}>
          {row.updated_by}
        </span>
      ),
    },
    {
      fieldName: "updated_at",
      label: "Updated On",
      sort: true,
      parentSelected: "is_approved",
      customRow: (row) => (
        <span className={classnames({ "opacity-50": !row.is_approved })}>
          <Moment format="DD/MM/YYYY">{row.updated_at}</Moment>
        </span>
      ),
    },
    {
      fieldName: "is_approved",
      label: "Status",
      width: 200,
      sort: true,
      parentSelected: "is_approved",
      customRow: (row) => (
        <b className="green">{row.is_approved ? "Approved" : ""}</b>
      ),
    },
  ];

  const [searchText, setSearchText] = useState(null);
  const handleChange = (e) => {
    setSearchText(e.target.value);
    setPageActive(1);
  };

  const [showEntry] = useState(10);
  const [pageActive, setPageActive] = useState(1);
  const [totalRecords, setTotalRecords] = useState(vendorList.length);

  const handlePagination = (e) => {
    setPageActive(e);
  };

  const changeTableParams = (total, page) => {
    if (page) setPageActive(page);
    setTotalRecords(total);
  };

  const editVendor = () => {
    setShowCheckboxes(true);
    setShowLoading(true);
  };

  const updateVendorStatus = async () => {
    const resStatus = await props.updateVendor(props.user.token, {
      distributors: JSON.stringify(newlyApprovedList),
    });
    if (resStatus.code >= 200 && resStatus.code < 400) {
      setShowCheckboxes(false);
      let selectedVendorslist = localStorage.getItem("selectedVendors").split(",");
      newlyApprovedList.forEach((item) => {
        if (item.is_approved === '1' && !selectedVendorslist.includes(item.id)) {
          selectedVendorslist.push(parseInt(item.id));
        }
        if (item.is_approved === '0' && selectedVendorslist.includes(item.id)) {
          const index = selectedVendorslist.indexOf(item.id);
          selectedVendorslist.splice(index, 1);
        }
      });
      localStorage.setItem("selectedVendors", selectedVendorslist);
      window.location.reload();
    }
    setNewlyApprovedList([]);
  };

  const cancel = async () => {
    setShowCheckboxes(false);
  };

  return (
    <Layouts>
      <div className="p-20">
        <div className="card">
          <div className="cardHeader">
            <h3>
              Vendors
              {vendorList.length ? (
                <small className="d-block">Total - {vendorList.length}</small>
              ) : (
                ""
              )}
            </h3>
            <div className="searchBox">
              <Input
                type="text"
                placeholder="Search Vendor"
                name="searchText"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {!showCheckboxes && (props.user.user.role_id === 1 || props.user.user.role_id === 2) && (
              <Button className="linkBtn" onClick={editVendor}>
                Edit Approval Status
              </Button>
            )}
            {showCheckboxes && showLoading && props.user.user.role_id === 1 && (
              <Button style={{width: '165px'}} className="linkBtn" disabled>
                Loading...
              </Button>
            )}
            <p className="notes mt-3">
              Note: To make vendors approved, Select vendor(s) from the
              following list and save changes. To remove just unchecked them.
            </p>
          </div>
          <Datatable
            data={vendorList}
            columns={column}
            pageActive={pageActive}
            showEntry={showEntry}
            searchText={searchText}
            changeTableParams={changeTableParams}
            defaultSortField="is_approved"
            defaultSortType="desc"
          />
          {showCheckboxes && (
            <Row>
              <Col className="px-5">
                <TextGroup>
                  <Button
                    className="btn-blue px-5 w-auto mr-2"
                    onClick={updateVendorStatus}
                  >
                    Save Changes
                  </Button>
                  <Button className="btn-gray px-5 w-auto" onClick={cancel}>
                    Cancel
                  </Button>
                </TextGroup>
              </Col>
            </Row>
          )}
        </div>
        {totalRecords > showEntry && (
          <Paginations
            totalRecords={totalRecords}
            showEntry={showEntry}
            pageActive={pageActive}
            click={handlePagination}
            prevClick={() => setPageActive(parseInt(pageActive) - 1)}
            nextClick={() => setPageActive(parseInt(pageActive) + 1)}
          />
        )}
      </div>
    </Layouts>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps, { getDistributor, updateVendor })(
  Vendor
);
