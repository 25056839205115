import styled from "styled-components";

export const colors = {
  BodyBg: "#f4f7fc",
  DarkBlueGrey: "#242f57",
  DarkBlue: "#0080ff",
  HoverDarkBlue: "#0060bf",
  FocusDarkBlue: "#004080",
  White: "#ffffff",
  BorderColor: "#e3e1e1",
  Red: "#ec4c4c",
};

export const common = {
  Radius: "4px",
  SidebarWidth: "243px",
  CollapsedWidth: "50px",
  HeaderHeight: "60px",
};

export const HeaderBlock = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  height: ${common.HeaderHeight};
  box-shadow: 0 2px 5px 0 rgba(28, 41, 90, 0.08);
  background-color: ${colors.White};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
`;
export const SidebarBlock = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  margin-top: ${common.HeaderHeight};
  width: ${common.SidebarWidth};
  height: calc(100vh - ${common.HeaderHeight});
  box-shadow: 0 9px 7px 0 rgba(28, 41, 90, 0.11);
  background-color: ${colors.White};
  overflow-y: auto;
  transition: width 0.4s ease;
  z-index: 1;
`;
export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
`;
export const Content = styled.div`
  margin-left: ${common.SidebarWidth};
  height: calc(100vh - ${common.HeaderHeight});
  overflow-y: auto;
  position: relative;
  top: ${common.HeaderHeight};
  transition: margin-left 0.4s ease;
  @media screen and (max-width: 767px) {
    margin-left: ${common.CollapsedWidth};
  }
`;
export const Body = styled.div`
  min-height: 100vh;
  background-color: ${colors.BodyBg};
  font-family: "Poppins", sans-serif;
  &.collapsed {
    ${Content} {
      margin-left: ${common.CollapsedWidth};
    }
    ${SidebarBlock} {
      width: ${common.CollapsedWidth};
      .navbar {
        justify-content: center;
        padding: 15px 0;
        .toggleNav {
          margin-left: -20px;
        }
      }
      .title {
        font-size: 0px;
      }
      .navigation .link-item a {
        padding: 12px 0;
        justify-content: center;
        .icon {
          margin: 0;
        }
        span {
          font-size: 0px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    &:not(.collapsed) {
      ${Overlay} {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
`;
export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media screen and (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
`;
export const TextLabel = styled.label`
  color: #636e95;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  display: inline-block;
`;
export const TextField = styled.input`
  border: 1px solid #bfc7e0;
  width: 100%;
  color: ${colors.DarkBlueGrey} !important;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  padding: 14px 21px;
  box-sizing: border-box;
  border-radius: ${common.Radius};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.sm {
    height: 40px;
    font-size: 15px;
    font-weight: normal;
    padding: 10px 14px;
  }
  &.error {
    border-color: ${colors.Red};
  }
  &:focus {
    border-color: ${colors.DarkBlue};
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;
export const Button = styled.button`
  border-radius: ${common.Radius};
  background-color: ${colors.DarkBlue};
  display: block;
  width: 100%;
  height: 50px;
  color: ${colors.White};
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 11px 15px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  transition: all 0.4s ease;
  &:hover {
    background-color: ${colors.HoverDarkBlue};
  }
  &:focus {
    background-color: ${colors.FocusDarkBlue};
  }
`;
export const InputGroupText = styled.span`
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #bfc7e0;
  cursor: pointer;
`;
export const InputGroupAppend = styled.div`
  display: flex;
  white-space: nowrap;
  vertical-align: middle;
  margin-left: -1px;
  ${InputGroupText} {
    border-left: none;
    border-top-right-radius: ${common.Radius};
    border-bottom-right-radius: ${common.Radius};
  }
`;
export const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  ${TextField} {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    &:focus {
      + ${InputGroupAppend} {
        ${InputGroupText} {
          border-color: ${colors.DarkBlue};
        }
      }
    }
  }
`;
export const TextGroup = styled.div`
  margin-bottom: 20px;
  &.error {
    ${TextField} {
      color: ${colors.Red};
    }
    ${TextField} {
      border-color: ${colors.Red};
    }
    ${InputGroupText} {
      border-color: ${colors.Red};
    }
    .error-text {
      font-size: 14px;
      color: ${colors.Red};
    }
  }
`;
export const CustomSelect = styled.div`
  position: relative;
  select,
  select.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(28, 41, 90, 0.08);
    background-color: #ffffff;
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    pointer-events: none;
  }
  &:after {
    content: "";
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid var(--gray);
    height: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 17px;
    pointer-events: none;
  }
`;
export const InputSwitchDebug = styled.label`
  display: block;
  cursor: pointer;
  input {
    display: none;
    + .span-text {
      position: relative;
      display: block;
      padding-right: 75px;
      font-size: 15px;
      font-weight: 500;
      &:before {
        content: "OFF";
        font-size: 12px;
        color: #ddd;
        position: absolute;
        display: block;
        width: 64px;
        height: 26px;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
        pointer-events: all;
        border-radius: 1.5rem;
        background-color: #fff;
        border: 1px solid #adb5bd;
        -webkit-transition: background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding-left: 30px;
        line-height: 27px;
      }
      &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 43px;
        width: calc(1.2rem - 4px);
        height: calc(1.2rem - 4px);
        border-radius: 0.5rem;
        background: #ddd;
        transition: transform 0.15s ease-in-out,
          background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
      }
    }
    &:checked + .span-text {
      &:before {
        color: #fff;
        border-color: #007bff;
        background-color: #007bff;
        padding-left: 10px;
        content: "ON";
      }
      &:after {
        background-color: #fff;
        transform: translateX(37px);
      }
    }
  }
`;

export const InputSwitchUser = styled.label`
  display: block;
  cursor: pointer;
  input {
    display: none;
    + .span-text {
      position: relative;
      display: block;
      padding-right: 75px;
      font-size: 15px;
      font-weight: 500;
      &:before {
        content: "OFF";
        font-size: 12px;
        color: #ddd;
        position: absolute;
        display: block;
        width: 64px;
        height: 26px;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
        pointer-events: all;
        border-radius: 1.5rem;
        background-color: #fff;
        border: 1px solid #adb5bd;
        -webkit-transition: background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding-left: 30px;
        line-height: 27px;
      }
      &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 43px;
        width: calc(1.2rem - 4px);
        height: calc(1.2rem - 4px);
        border-radius: 0.5rem;
        background: #ddd;
        transition: transform 0.15s ease-in-out,
          background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;
      }
    }
    &:checked + .span-text {
      &:before {
        color: #fff;
        border-color: #007bff;
        background-color: #007bff;
        padding-left: 10px;
        content: "ON";
      }
      &:after {
        background-color: #fff;
        transform: translateX(37px);
      }
    }
  }
`;
