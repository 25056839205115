import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import classnames from "classnames";
import * as _ from "lodash";
import "./datatableStyle.sass";

const CreateTable = (props) => {
  const [columns, setColumns] = useState([]);
  const [records, setRecords] = useState([]);
  const [sortField, setSortField] = useState(props?.defaultSortField !== undefined && props?.defaultSortField !== null ? props?.defaultSortField : null);
  const [sortType, setSortType] = useState(props?.defaultSortType !== undefined && props?.defaultSortType !== null ? props?.defaultSortType : "asc");

  useEffect(() => {
    let data = props.records ? [...props.records] : [];
    setColumns(props.columns);

    let field = sortField ? sortField : props.columns[0].fieldName;
    let sortedData = _.orderBy(
      data,
      [
        (row) =>
          row[field] ? row[field].toString().toLowerCase() : row[field],
      ],
      sortType
    );

    let totalFilteredData = sortedData;
    if (props.searchText) {
      totalFilteredData = sortedData.filter((item) => {
        for (let i = 0; i < props.columns.length; i++) {
          let str = item[props.columns[i].fieldName];
          if (
            typeof str === "string" &&
            str.toLowerCase().search(props.searchText.toLowerCase()) !== -1
          ) {
            return true;
          }
        }
        return false;
      });
    }
    let startIndex =
      parseInt(props.showEntry) * (parseInt(props.pageActive) - 1);
    let endIndex = parseInt(props.showEntry) * parseInt(props.pageActive);
    let pageFilteredData = totalFilteredData.slice(startIndex, endIndex);

    props.changeTableParams(totalFilteredData.length);
    setRecords(pageFilteredData);
    setSortField(field);
  }, [props, sortType, sortField]);

  const sortToggle = (field) => {
    setSortField(field);
    setSortType(sortType === "asc" ? "desc" : "asc");
  };

  return (
    <Table className="m-0">
      <thead>
        <tr>
          {/* eslint-disable-next-line array-callback-return */}
          {columns.map((item, index) => {
            if (!item?.dontShow) {
              return (
                <th
                  key={index}
                  style={{
                    textAlign: item.align ? item.align : "left",
                    width: item.width ? item.width : "auto",
                  }}
                >
                  <span
                    className={classnames(
                      { sort: item.sort },
                      { ["sort_" + sortType]: sortField === item.fieldName }
                    )}
                    onClick={() => sortToggle(item.fieldName)}
                  >
                    {item.label}
                  </span>
                </th>
              );
            }
          })}
        </tr>
      </thead>
      <tbody>
        {records.length ? (
          records.map((trItem, trIndex) => {
            return (
              <tr key={`tr_${trIndex}`}>
                {/* eslint-disable-next-line array-callback-return */}
                {columns.map((item, tdIndex) => {
                  if (!item?.dontShow) {
                    return (
                      <td
                        key={`td_${tdIndex}`}
                        className={classnames(item.class, {
                          selected: trItem[item.parentSelected]
                            ? trItem[item.parentSelected]
                            : false,
                        })}
                        style={{ textAlign: item.align ? item.align : "left" }}
                      >
                        {item.customRow
                          ? item.customRow(trItem)
                          : trItem[item.fieldName]}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={columns.length}>No records found.</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default CreateTable;
