import React, { useState, useEffect } from "react";
import classnames from "classnames";

const Paginations = (props) => {
  const [pageList, setPageList] = useState([]);
  const [pageLength, setPageLength] = useState(1);
  const [pageActive, setPageActive] = useState(1);

  useEffect(() => {
    let page = Math.ceil(props.totalRecords / parseInt(props.showEntry));
    let paginationActive = parseInt(props.pageActive);
    let pagination = [];
    if (page) {
      pagination = [];
      if (page <= 7) {
        for (let i = 1; i <= page; i++) {
          pagination.push(i);
        }
      } else {
        if (paginationActive >= 4 && paginationActive + 1 < page) {
          pagination.push(1);
          pagination.push("...");

          for (let j = paginationActive - 1; j <= paginationActive + 1; j++) {
            pagination.push(j);
          }
          pagination.push("...");
          pagination.push(page);
        } else {
          for (let k = 1; k <= 2; k++) {
            pagination.push(k);
          }

          if (paginationActive < 4) {
            for (let k = 3; k <= 4; k++) {
              pagination.push(k);
            }
            pagination.push("...");
            pagination.push(page);
          } else {
            pagination.push("...");
            for (let l = paginationActive - 1; l <= page; l++) {
              pagination.push(l);
            }
          }
        }
      }
    } else {
      pagination = [];
    }
    setPageList(pagination);
    setPageActive(props.pageActive);
    setPageLength(page);
  }, [props]);

  return (
    <ul className="pagination">
      {pageActive.toString() === "1" ? (
        <li className="no-border link" disabled={true}>
          Previous
        </li>
      ) : (
        <li className="no-border link" onClick={props.prevClick}>
          Previous
        </li>
      )}
      {pageList.map((item, index) => {
        if (item === "...") {
          return (
            <li key={index} disabled>
              {item}
            </li>
          );
        }

        return (
          <li
            key={index}
            className={classnames({ active: item === pageActive })}
            onClick={() => props.click(item)}
          >
            {item}
          </li>
        );
      })}
      {pageActive.toString() === pageLength.toString() ? (
        <li className="no-border link" disabled={true}>
          Next
        </li>
      ) : (
        <li className="no-border link" onClick={props.nextClick}>
          Next
        </li>
      )}
    </ul>
  );
};

export default Paginations;
