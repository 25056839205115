import React, { useState } from "react";
import { connect } from "react-redux";
import { forgotPassword } from "../../actions";
import classNames from "classnames";

import styled from "styled-components";
import { Container, TextField, TextLabel, TextGroup, Button } from "../Styled";
import { ToastsStore } from "react-toasts";
import { Alert } from "reactstrap";

import { formValidation, toaster } from "../../function.js";

const LoginPage = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #f4f7fc;
  font-family: "Poppins", sans-serif;
`;
const LoginBox = styled.div`
  width: 406px;
  max-width: calc(100% - 40px);
  margin: 20px auto;
  padding: 52px 26px 60px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(28, 41, 90, 0.08);
  background-color: #ffffff;
  @media screen and (max-width: 550px) {
    padding: 25px;
  }
`;
const LoginTitle = styled.h2`
  color: #242f57;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 21px;
`;
const CopyRight = styled.p`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #636e95;
  margin-top: 42px;
`;
const requiredField = {
  email: ["required", "email"],
};

const ForgotPasswordForm = ({ onSubmit, onChange, passwordSent, errors }) => {
  return (
    <form onSubmit={onSubmit} onChange={onChange}>
      {errors.commonError ? (
        <Alert color="danger" className="error-text">
          {errors.commonError}
        </Alert>
      ) : null}
      <TextGroup
        className={classNames({ error: errors.email || errors.commonError })}
      >
        <TextLabel>Please enter you registered email address</TextLabel>
        <TextField type="text" name="email" placeholder="Enter email" />
        {errors.email ? (
          <span className="error-text">{errors.email}</span>
        ) : null}
      </TextGroup>
      {passwordSent ? (
        <TextLabel>
          We've sent a new password to your registered email. Please check your
          inbox and <a href="/login">Login</a> with the new password.
        </TextLabel>
      ) : null}
      <Button type="submit">
        {passwordSent ? "Resend" : "Send a new Password"}
      </Button>
    </form>
  );
};

const ForgotPassword = ({ forgotPassword }) => {
  const [formError, setFormError] = useState({});
  const [passwordSent, setPasswordSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      email: { value: email },
    } = e.target;

    const validate = formValidation(requiredField, { email });
    setFormError(validate.errors);

    if (validate.formIsValid) {
      const data = await forgotPassword({ email });
      if (data.code === 404 || data.code === 403) {
        setFormError({
          commonError: data.message,
        });
      } else {
        setPasswordSent(true);
      }
    } else {
      toaster(ToastsStore, "error", "There were some errors.");
    }
  };

  const handleChange = async (e) => {
    setFormError({});
  };

  return (
    <React.Fragment>
      <LoginPage>
        <Container>
          <LoginBox>
            <LoginTitle>
              <img src="/images/Logo.png" alt="Logo" />
            </LoginTitle>
            <ForgotPasswordForm
              onSubmit={handleSubmit}
              onChange={handleChange}
              passwordSent={passwordSent}
              errors={formError}
            />
          </LoginBox>
          <CopyRight>Powered by Trylene. © 2020 All rights reserved</CopyRight>
        </Container>
      </LoginPage>
    </React.Fragment>
  );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
