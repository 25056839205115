import React from "react";
import classnames from "classnames";
import { Row, Col, Button } from "reactstrap";
import { ModalOverlay, ModalBox } from "./ModalStyled";
import { Link } from "react-router-dom";
import { ClockIcon } from "../../constants/icons";

function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min " : " min ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " secs" : " secs") : "";
  return hDisplay + mDisplay + sDisplay;
}

const SessionOut = (props) => {
  return (
    <ModalOverlay className={classnames({ show: props.show })}>
      <ModalBox className="smBox">
        <h2 className="modal-title">Session Timeout</h2>
        <div className="modal-text timer-text">
          <ClockIcon />
          <div>
            <small>Your online session will expire in</small>
            <br />
            <span className="counter">{secondsToHms(props.counter)}</span>
          </div>
        </div>
        <p className="modal-text">
          Please click ”Continue” to keep working or click ”Log Out” to end your
          session now.
        </p>
        <Row>
          <Col>
            <Link to="/logout">
              <Button className="btn-gray" block>
                Log Out
              </Button>
            </Link>
          </Col>
          <Col>
            <Button className="btn-blue" block onClick={props.sessionToggle}>
              Continue
            </Button>
          </Col>
        </Row>
      </ModalBox>
    </ModalOverlay>
  );
};

export default SessionOut;
