import React, { useState, useEffect } from "react";
import "./dropdown.css";
import { Label } from "reactstrap";
import classNames from "classnames";
import {
  InputGroupAppend,
  TextField,
  InputGroupText,
  InputGroup,
} from "./../Styled";

const Dropdown = React.forwardRef((props, ref) => {
  const { data, isOpen, placeholder, handleChangeEvent, name } = props;

  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    setSearchData(data);
  }, [data]);

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e) => {
    if (ref.current.contains(e.target)) {
      return;
    }
    // outside click
    props.click(props.type);
  };

  const searchCustomer = (e) => {
    if (e.target.value === "") {
      setSearchData(data);
    } else {
      let filterData = searchData.filter((item) => {
        if (
          item.name.toLowerCase().match(e.target.value.toLowerCase()) !== null
        ) {
          return item;
        }
        return false;
      });
      setSearchData(filterData);
    }
  };

  return (
    <ul
      ref={ref}
      className={classNames("userDropdown background", { open: isOpen })}
    >
      <li>
        <InputGroup className="search">
          <TextField
            className="search sm"
            type="text"
            name="search"
            placeholder={placeholder}
            onChange={searchCustomer}
          />
          <InputGroupAppend>
            <InputGroupText>
              <img src="images/search.svg" alt="search" height="18" />
            </InputGroupText>
          </InputGroupAppend>
        </InputGroup>
      </li>
      {searchData.map((element, index) => (
        <li key={index}>
          <Label className="customRadio d-block m-0">
            <input
              type="radio"
              name={props.name.name}
              value={element.id}
              onChange={(e) => handleChangeEvent(e, element)}
              checked={name.id === element.id ? true : false}
            />
            <span className="span-text">{element.name}</span>
          </Label>
        </li>
      ))}
    </ul>
  );
});

export default Dropdown;
