import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import * as FileSaver from "file-saver";
import Datatable from "../../Datatable";
import Paginations from "../../Datatable/Paginations";
import { getBomHistory, getFile, shareFile } from "../../../actions";
import { connect } from "react-redux";
import { ImportIcon, EmailIcon } from "../../../constants/icons";
import "./historyStyle.css";
import { toaster } from "../../../function";
import { ToastsStore } from "react-toasts";

const HistoryTable = (props) => {
  const [bomList, setBomList] = useState([]);

  useEffect(() => {
    const getBoms = async () => {
      const boms = await props.getBomHistory(props.user.token);
      setBomList(boms.data);
    };

    getBoms();
  }, [props]);

  const importBom = async (row) => {
    const fileBlob = await props.getFile(props.user.token, row.file_path);
    fileBlob.name = row.file_name + ".xlsx";
    props.fileUpload([fileBlob]);
  };

  const column = [
    {
      fieldName: "file_name",
      label: "File Name",
      sort: true,
      customRow: (row) =>
        props.isHistoryPage ? (
          <a href={`/history/${row.id}`}>{row.file_name}</a>
        ) : (
          <span className="clickableSpan" onClick={() => importBom(row)}>
            {row.file_name}
          </span>
        ),
    },
    {
      fieldName: "customer_name",
      label: "Customer Name",
      sort: true,
      customRow: (row) => <span>{row.customer_name ?? "N/A"}</span>,
    },
    {
      fieldName: "part_rev",
      label: "Part REV#",
      sort: true,
      customRow: (row) => <span>{row.part_rev ?? "N/A"}</span>,
    },
    {
      fieldName: "date_of_upload",
      label: "Date of upload",
      sort: true,
      customRow: (row) => <span>{row.date_of_upload}</span>,
    },
    {
      fieldName: "created_by",
      label: "Created By",
      sort: true,
      customRow: (row) => <span>{row.created_by}</span>,
    },
    props.isHistoryPage
      ? {
          fieldName: "action",
          label: "Action",
          sort: true,
          customRow: (row) => (
            <React.Fragment>
              <span className="historyEmailIcon">
                <EmailIcon
                  className="mr-2"
                  cursor={"pointer"}
                  onClick={async () => {
                    let data = await props.shareFile(
                      props.user.token,
                      row.result_file_path,
                      "Result-" + row.file_name + ".xlsx"
                    );
                    if (data.status >= 200 && data.status < 400) {
                      toaster(
                        ToastsStore,
                        "success",
                        "Sent the BOM file to your registered email."
                      );
                    } else {
                      data = await props.shareFile(
                        props.user.token,
                        row.file_path,
                        row.file_name + ".xlsx"
                      );
                      if (data.status >= 200 && data.status < 400) {
                        toaster(
                          ToastsStore,
                          "success",
                          "Sent the BOM file to your registered email."
                        );
                      } else if (data.status === 404) {
                        toaster(
                          ToastsStore,
                          "error",
                          "Could not find the file."
                        );
                      } else {
                        toaster(
                          ToastsStore,
                          "error",
                          "Could not get the file."
                        );
                      }
                    }
                  }}
                />
              </span>
              <span className="historyDownloadIcon">
                <ImportIcon
                  className="mr-2"
                  cursor={"pointer"}
                  onClick={async () => {
                    const fileBlob = await props.getFile(
                      props.user.token,
                      row.file_path
                    );
                    const resultFileBlob = await props.getFile(
                      props.user.token,
                      row.result_file_path
                    );
                    if (fileBlob !== null) {
                      FileSaver.saveAs(fileBlob, row.file_name + ".xlsx");
                    }
                    if (resultFileBlob !== null) {
                      FileSaver.saveAs(
                        resultFileBlob,
                        "Result-" + row.file_name + ".xlsx"
                      );
                    }
                  }}
                />
              </span>
            </React.Fragment>
          ),
        }
      : {},
  ];

  const [searchText, setSearchText] = useState(null);
  const handleChange = (e) => {
    setSearchText(e.target.value);
    setPageActive(1);
  };

  const [showEntry] = useState(10);
  const [pageActive, setPageActive] = useState(1);
  const [totalRecords, setTotalRecords] = useState(bomList.length);

  const handlePagination = (e) => {
    setPageActive(e);
  };

  const changeTableParams = (total, page) => {
    if (page) setPageActive(page);
    setTotalRecords(total);
  };

  return (
    <React.Fragment>
      <div className="p-20">
        <div className="card">
          <div className="history cardHeader">
            <h3>
              History
              <small className="d-block">Total - {bomList?.length ?? 0}</small>
            </h3>
            <div className="history searchBox">
              <Input
                type="text"
                placeholder="Search"
                name="searchText"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <Datatable
            data={bomList}
            columns={column}
            pageActive={pageActive}
            showEntry={showEntry}
            searchText={searchText}
            changeTableParams={changeTableParams}
          />
        </div>
        {totalRecords > showEntry && (
          <Paginations
            totalRecords={totalRecords}
            showEntry={showEntry}
            pageActive={pageActive}
            click={handlePagination}
            prevClick={() => setPageActive(parseInt(pageActive) - 1)}
            nextClick={() => setPageActive(parseInt(pageActive) + 1)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps, { getBomHistory, getFile, shareFile })(
  HistoryTable
);
