import React from "react";
import "./css/sidebar.css";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { SidebarBlock } from "../Styled";
import {
  HomeIcon,
  UserIcon,
  HistoryIcon,
  BriefcaseIcon,
} from "../../constants/icons";

const Sidebar = (props) => {
  const { handleClick, user } = props;
  return (
    <SidebarBlock className="sidebar">
      <div className="navbar">
        <h5 className="title">Navigation</h5>
        <button className="toggleNav" onClick={handleClick}>
          <span className="bars"></span>
        </button>
      </div>
      <ul className={classNames("navigation")}>
        <li className="link-item">
          <NavLink exact activeClassName="active" to="/">
            <HomeIcon className="icon fill-gray" />
            <span>Home</span>
          </NavLink>
        </li>
        {user && (user.user.role_id === 1 || user.user.role_id === 2) ? (
          <li className="link-item">
            <NavLink exact activeClassName="active" to="/vendor">
              <BriefcaseIcon className="icon fill-gray" />
              <span>Vendors</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {user && (user.user.role_id === 1 || user.user.role_id === 2) ? (
          <li className="link-item">
            <NavLink exact activeClassName="active" to="/user">
              <UserIcon className="icon stroke fill-gray" />
              <span>Users</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        <li className="link-item">
          <NavLink exact activeClassName="active" to="/history">
            <HistoryIcon className="icon" />
            <span>History</span>
          </NavLink>
        </li>
        {/* <li className="link-item">
          <Link to="/">
            <PieIcon className="icon stroke fill-gray" />
            <span>Analytics</span>
          </Link>
        </li> */}
        {/* <li className="link-item mt-auto">
          <Link to="/">
            <img className="icon" src="/images/Settings.png" alt="Analytics" />
            <span>Settings</span>
          </Link>
        </li> */}
      </ul>
    </SidebarBlock>
  );
};

export default Sidebar;
