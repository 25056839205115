import React, { Component } from "react";
import classNames from "classnames";
import onClickOutside from "react-onclickoutside";
import { Link, NavLink } from "react-router-dom";

class UserDropdown extends Component {
  handleClickOutside = () => {
    return this.props.click();
  };

  render() {
    const { showDropDown, user, userInfo } = this.props;
    return (
      <div className="userBlock" onClick={showDropDown}>
        {/* <img src="/images/user.svg" alt="user" width="36" /> */}
        <div className="userInfo">
          <h5>{user.user.first_name + " " + user.user.last_name}</h5>
          <span>{user.user.role}</span>
        </div>
        <span className="caret"></span>
        <ul className={classNames("userDropdown", { open: userInfo })}>
          <li>
            <NavLink activeClassName="active" to="/profile">
              Profile
            </NavLink>
          </li>
          <li>
            <Link to="/logout">Logout</Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default onClickOutside(UserDropdown);
