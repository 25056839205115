import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import throttle from "lodash/throttle";

import rootReducer from "./reducers";
import { saveState, loadState } from "./helpers";

export const store = createStore(
  rootReducer,
  loadState(),
  compose(applyMiddleware(thunk))
);

store.subscribe(throttle(() => saveState(store.getState()), 1000));
