import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions";

const Logout = ({ user, logout, history }) => {
  useEffect(() => {
    if (Object.keys(user).length) {
      logout(user.token);
    }
    localStorage.clear();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Redirect to="/login" />;
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps, { logout })(Logout);
