import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const BomPageQuoteTable = (props) => {
  const [quotesQuantity, setQuotesQuantity] = useState([]);
  const [quotesResult, setQuotesResult] = useState([]);

  useEffect(() => {
    if (props?.bomDetails?.data?.result_price_and_coverage) {
      setQuotesQuantity(
        Object.keys(props.bomDetails?.data.result_price_and_coverage)
      );
      setQuotesResult(
        Object.values(props.bomDetails?.data.result_price_and_coverage)
      );
    }
  }, [props]);

  return (
    <div className="bomPageQuoteTable">
      <table className="bordered m-0">
        <thead>
          <tr>
            <th></th>
            <th>Quote 1</th>
            <th>Quote 2</th>
            <th>Quote 3</th>
            <th>Quote 4</th>
            <th>Quote 5</th>
            <th>Quote 6</th>
          </tr>
        </thead>
        <tbody>
          <tr className="quantity">
            <td>Quantity</td>
            <td>{quotesQuantity[0] ?? "N/A"}</td>
            <td>{quotesQuantity[1] ?? "N/A"}</td>
            <td>{quotesQuantity[2] ?? "N/A"}</td>
            <td>{quotesQuantity[3] ?? "N/A"}</td>
            <td>{quotesQuantity[4] ?? "N/A"}</td>
            <td>{quotesQuantity[5] ?? "N/A"}</td>
          </tr>
          <tr className="price">
            <td>Percentage</td>
            <td>
              {quotesResult[0] ? quotesResult[0]?.percentage + "%" : "N/A"}
            </td>
            <td>
              {quotesResult[1] ? quotesResult[1]?.percentage + "%" : "N/A"}
            </td>
            <td>
              {quotesResult[2] ? quotesResult[2]?.percentage + "%" : "N/A"}
            </td>
            <td>
              {quotesResult[3] ? quotesResult[3]?.percentage + "%" : "N/A"}
            </td>
            <td>
              {quotesResult[4] ? quotesResult[4]?.percentage + "%" : "N/A"}
            </td>
            <td>
              {quotesResult[5] ? quotesResult[5]?.percentage + "%" : "N/A"}
            </td>
          </tr>
          <tr className="price">
            <td>Total Price</td>
            <td>{quotesResult[0] ? "$" + quotesResult[0]?.total : "N/A"}</td>
            <td>{quotesResult[1] ? "$" + quotesResult[1]?.total : "N/A"}</td>
            <td>{quotesResult[2] ? "$" + quotesResult[2]?.total : "N/A"}</td>
            <td>{quotesResult[3] ? "$" + quotesResult[3]?.total : "N/A"}</td>
            <td>{quotesResult[4] ? "$" + quotesResult[4]?.total : "N/A"}</td>
            <td>{quotesResult[5] ? "$" + quotesResult[5]?.total : "N/A"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, {})(BomPageQuoteTable);
