import React from "react";
import "./css/header.css";
import { HeaderBlock, CustomSelect, InputSwitchDebug } from "../Styled";
import VendorDropdown from "./VendorDropdown";
import UserDropdown from "./UserDropdown";
import { connect } from "react-redux";
import { getDistributor } from "../../actions";
import _ from "lodash";

const vendorDropdownDropButtonName = "vendorDropDownDropButton";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: false,
      vendorShow: false,
      adminselectedVendors: [],
      selectedVendors: [],
      vendors: [],
      vendorList: [],
      filterVendorList: [],
      alphabate: "",
    };
  }

  async componentDidMount() {
    const vendors = await this.props.getDistributor(this.props.user.token);

    let adminselectedVendors = [];
    vendors.data.forEach((item) => {
      if (item.is_approved) {
        adminselectedVendors.push(parseInt(item.id));
      }
    });

    let selectedVendors = [];
    if (localStorage.getItem("selectedVendors") == null) {
      selectedVendors = adminselectedVendors.slice();
      localStorage.setItem("selectedVendors", selectedVendors);
    } else {
      let selectedVendorsStringFromLocalStorage = localStorage
        .getItem("selectedVendors")
        .split(",");
      selectedVendorsStringFromLocalStorage.forEach((item) => {
        if(item){
          selectedVendors.push(parseInt(item));
        }
      });
    }

    this.setState({
      vendors: vendors.data,
      selectedVendors: selectedVendors,
      adminselectedVendors: adminselectedVendors,
    });
    const result = this.groupByAlphabate(vendors.data);
    this.setState({
      vendorList: result,
      filterVendorList: result,
    });
  }

  groupByAlphabate = (data) => {
    data = _.sortBy(data, "name");
    const result = _(data)
      .groupBy((o) => o.name[0].toUpperCase())
      .map((records, letter) => ({ letter, records }))
      .value();

    return this.getSortedResultsWithAdminAndSessionSelection(data, result);
  };

  getSortedResultsWithAdminAndSessionSelection = (data, result) => {
    let resultSort = _.sortBy(result, "letter");
    let sessionSelectedVendorsIndex = [];
    this.state.selectedVendors.forEach((item) => {
      if (this.state.adminselectedVendors.includes(item) === false) {
        sessionSelectedVendorsIndex.push(item);
      }
    });

    if (sessionSelectedVendorsIndex.length !== 0) {
      let sessionSelectedVendors = data.filter(
        (vendor) => sessionSelectedVendorsIndex.includes(vendor.id) === true
      );
      resultSort.unshift({
        letter: "Session",
        records: sessionSelectedVendors,
      });
    }

    let approvedVendors = data.filter((vendor) => vendor.is_approved);
    resultSort.unshift({ letter: "Admin", records: approvedVendors });

    return resultSort;
  };

  handleClickOutside = (e) => {
    if (e.target.id !== vendorDropdownDropButtonName) {
      this.setState({
        vendorShow: false,
      });
    }
  };

  handleChangeVendor = (e) => {
    let selectedVendorsState = this.state.selectedVendors;
    if (e.target.checked) {
      selectedVendorsState.push(parseInt(e.target.value));
    } else {
      let index = selectedVendorsState.indexOf(parseInt(e.target.value));
      selectedVendorsState.splice(index, 1);
    }
    localStorage.setItem("selectedVendors", selectedVendorsState);
    this.setState({ selectedVendors: selectedVendorsState });

    const result = this.groupByAlphabate(this.state.vendors);

    // Update the whole list only when the specific selection is not done.
    // i.e. if alphabet 'A' is selected we leave the list as is,
    // update only when ther is not filter
    if (this.state.alphabate === "" || this.state.alphabate === "#") {
      this.setState({
        filterVendorList: result,
      });
    }

    this.setState({
      vendorList: result,
    });
  };

  showDropDown = () => {
    this.setState({ userInfo: !this.state.userInfo });
  };

  hideDropDown = () => {
    this.setState({ userInfo: false });
  };

  isDebug(e) {
    localStorage.setItem("isDebug", e.target.checked ? 1 : 0);
  }

  selectAlphabate = (e) => {
    this.setState({ alphabate: e });
    if (e === "#") {
      return this.setState({ filterVendorList: this.state.vendorList });
    }
    let filterVendorList = this.state.vendorList.filter(
      (item) => item.letter === e
    );
    this.setState({ filterVendorList });
  };

  searchVendor = (searchText) => {
    const newData = this.state.vendors.filter((item) => {
      let str = item.name;
      if (searchText) {
        if (
          typeof str === "string" &&
          str.toLowerCase().search(searchText.toLowerCase()) !== -1
        ) {
          return item;
        }
        return false;
      }
      return item;
    });

    const searchRecords = this.groupByAlphabate(newData);
    this.setState({ filterVendorList: searchRecords, alphabate: "#" });
  };

  render() {
    const { userInfo, selectedVendors } = this.state;
    const { user } = this.props;

    return (
      <HeaderBlock>
        <h5 className="logo">
          <img src="/images/Logo.png" alt="Logo" />
        </h5>
        <InputSwitchDebug className="debugSwitch m-0">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitchesChecked"
            onChange={this.isDebug}
            name="is_active"
            defaultChecked={
              localStorage.getItem("isDebug") === "1" ? true : false
            }
          />
          <span className="span-text">Debug</span>
        </InputSwitchDebug>
        <div className="centerBox">
          <span>Vendors</span>
          <CustomSelect>
            <div
              className="form-control"
              id={vendorDropdownDropButtonName}
              onClick={() =>
                this.setState({ vendorShow: !this.state.vendorShow })
              }
            >
              {selectedVendors.length} selected
            </div>
            <VendorDropdown
              show={this.state.vendorShow}
              data={this.state.filterVendorList}
              allData={this.state.vendorList}
              alphabate={this.state.alphabate}
              selectAlphabate={this.selectAlphabate}
              user={user}
              searchVendor={this.searchVendor}
              click={this.handleClickOutside}
              handleChangeVendor={this.handleChangeVendor}
              selectedVendors={this.state.selectedVendors}
            />
          </CustomSelect>
        </div>
        <UserDropdown
          showDropDown={this.showDropDown}
          user={user}
          userInfo={userInfo}
          click={this.hideDropDown}
        />
      </HeaderBlock>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, { getDistributor })(Header);
