import React, { useState } from "react";
import Layouts from "../../Layouts";
import { Row, Col, Button } from "reactstrap";
import { TextGroup, TextLabel } from "../../Styled";
import { connect } from "react-redux";
import { profileUpdate } from "../../../actions";
import EditPassword from "./EditPassword";
import EditProfile from "./EditProfile";
import { formValidation, toaster } from "../../../function";
import { ToastsStore } from "react-toasts";

const Profile = (props) => {
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const { user } = props.user;

  const requiredField = {
    first_name: ["required"],
    last_name: ["required"],
    phone_number: ["required"],
  };

  const toggleEditPassword = () => {
    let check = !showEditPassword;
    setShowEditPassword(check);
  };

  const toggleEditProfile = () => {
    let check = !showEditProfile;
    setEditFormData(user);
    setShowEditProfile(check);
  };

  const handleEditFormChange = (e) => {
    setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
  };

  const userUpdate = async (e) => {
    e.preventDefault();

    const postUpdateData = {
      id: editFormData.id,
      first_name: editFormData.first_name,
      last_name: editFormData.last_name,
      phone_number: editFormData.phone_number,
      role: user.role_id,
      allow_password_reset: true,
    };

    let validateField = { ...requiredField };
    delete validateField.email;

    let validate = formValidation(validateField, postUpdateData);

    if (validate.formIsValid) {
      const resUser = await props.profileUpdate(
        props.user.token,
        postUpdateData
      );

      if (resUser.code >= 200 && resUser.code < 400) {
        setShowEditProfile(false);
        toaster(ToastsStore, "success", resUser.message);
      } else {
        toaster(ToastsStore, "error", resUser.message);
      }
    }
  };

  return (
    <Layouts>
      <div className="p-20">
        <h3 className="pageTitle">My Profile</h3>
        <Row>
          <Col lg="6">
            {showEditProfile ? (
              <EditProfile
                fillData={editFormData}
                user={user}
                change={handleEditFormChange}
                submit={userUpdate}
              />
            ) : (
              <div className="card">
                <div className="cardHeader">
                  <h3>Personal Information</h3>
                  <Button
                    color="default"
                    className="link"
                    onClick={toggleEditProfile}
                  >
                    {showEditProfile ? "" : "Edit"}
                  </Button>
                </div>
                <div className="cardBody pt-0">
                  <Row>
                    <Col md={6}>
                      <TextGroup>
                        <TextLabel>First Name</TextLabel>
                        <p className="fieldInfo">{user.first_name}</p>
                      </TextGroup>
                    </Col>
                    <Col md={6}>
                      <TextGroup>
                        <TextLabel>Last Name</TextLabel>
                        <p className="fieldInfo">{user.last_name}</p>
                      </TextGroup>
                    </Col>
                    <Col md={12}>
                      <TextGroup>
                        <TextLabel>Email Address</TextLabel>
                        <p className="fieldInfo">{user.email}</p>
                      </TextGroup>
                    </Col>
                    <Col md={12}>
                      <TextGroup>
                        <TextLabel>Phone Number </TextLabel>
                        <p className="fieldInfo">{user.phone_number}</p>
                      </TextGroup>
                    </Col>
                    <Col md={6}>
                      <TextGroup>
                        <TextLabel>Role</TextLabel>
                        <p className="fieldInfo">{user.role}</p>
                      </TextGroup>
                    </Col>
                    <Col md={6}>
                      <TextGroup>
                        <TextLabel>Status</TextLabel>
                        <p className="fieldInfo">
                          {user.is_active === 1 ? "Active" : "Deactive"}
                        </p>
                      </TextGroup>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </Col>
          <Col lg="6">
            <div className="card">
              <div className="cardHeader">
                <h3>Password Settings</h3>
                <Button
                  color="default"
                  className="link"
                  onClick={toggleEditPassword}
                >
                  {showEditPassword ? "" : "Reset"}
                </Button>
              </div>
              <div className="cardBody">
                {showEditPassword ? (
                  <EditPassword close={toggleEditPassword} />
                ) : (
                  <TextGroup>
                    <TextLabel>Password</TextLabel>
                    <p className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </p>
                  </TextGroup>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layouts>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, { profileUpdate })(Profile);
