import styled from "styled-components";
import { CustomSelect, TextLabel } from "../../Styled";

export const DropBox = styled.div`
  height: 390px;
  border: 1.5px dashed rgba(99, 110, 149, 0.55);
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  &.highlightedBox {
    border-color: #007bff;
    img {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  img {
    margin-bottom: 20px;
    &.active {
      display: none;
    }
  }
  h5 {
    font-size: 20px;
    color: #636e95;
    margin: 0;
  }
  span {
    font-size: 13px;
    color: #636e95;
  }
  @media screen and (max-width: 550px) {
    h5 {
      font-size: 16px;
    }
  }
`;
export const AddNewBlock = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 33%;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  box-shadow: 0 2px 19px 0 rgba(152, 152, 152, 0.37);
  background-color: var(--white);
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s ease;
  z-index: 1100;
  &.open {
    transform: translate3d(0, 0, 0);
  }

  .importBtn:hover {
    background-color: #bfc7e0;
  }
  @media screen and (max-width: 991px) {
    width: 50%;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`;
export const FileInfo = styled.div`
  display: inline-block;
  margin: 0 50px 20px 0;
  ${TextLabel} {
    margin-bottom: 0;
  }
  &:last-child {
    margin-right: 10px;
  }
  h5 {
    font-size: 16px;
    color: var(--dark-blue-grey);
  }
`;
export const PreviewHead = styled.div`
  padding: 25px 25px 10px;
  background-color: #f4f7fc;
  border-radius: 8px;
  margin-bottom: 20px;
  .successAlert {
    margin-bottom: 15px;
    h5 {
      font-size: 18px;
      margin: 0;
      color: var(--green-blue);
      img {
        margin-right: 10px;
        vertical-align: -4px;
        max-width: 22px;
      }
    }
    span {
      color: var(--dark-blue-grey);
      font-size: 15px;
      padding-left: 32px;
    }
  }
  .fileInfo {
    margin-bottom: 15px;
    h5 {
      font-size: 18px;
      display: flex;
      align-items: center;
      margin: 0;
      color: #636e95;
      img {
        margin-right: 10px;
        vertical-align: -4px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        display: block;
        + span {
          font-size: 15px;
          color: var(--dark-blue-grey);
        }
      }
    }
  }
  .importCloseIcon {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 25px;
  }
`;
export const FixHead = styled.tr`
  ${CustomSelect} {
    min-width: 120px;
    select,
    select.form-control {
      border-radius: 0px !important;
      border: none !important;
    }
  }
  select.bg-green,
  select.bg-green:focus {
    background-color: var(--green-blue) !important;
    color: var(--white) !important;
  }
  select.ignored,
  select.ignored:focus {
    background-color: #f2a626 !important;
    color: var(--white) !important;
  }
`;

export const SelectDropdown = styled.div`
  position: relative;
  .selected {
    padding: 11px 14px;
    font-size: 14px;
    font-weight: normal;
  }
  .dropdownList {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 90%;
    box-shadow: 0 2px 5px 0 rgba(28, 41, 90, 0.08);
    border-radius: 4px;
    overflow: hidden;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
    li {
      padding: 8px 14px;
      border-bottom: 1px solid rgb(222 226 230 / 0.5);
      &:last-child {
        border-bottom: none;
      }
      &:not(:disabled):hover,
      &.active {
        background-color: #f9f9f9;
      }
      &:disabled {
        opacity: 0.7;
      }
    }
  }
`;
