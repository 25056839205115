//Form validation function
var formValidation = function (requiredField, state) {
  this.state = state;
  let errors = {};
  let formIsValid = true;

  Object.keys(requiredField).forEach((key) => {
    // Get field name and it's rules array
    return requiredField[key].map((rule) => {
      // Check validate with only required
      if (!this.state[key] && rule === "required") {
        formIsValid = false;
        errors[key] = key.replace(/_/g, "-") + " is required.";
      }

      if (!this.state[key] && rule === "length") {
        if (!this.state[key].length) {
          formIsValid = false;
          errors[key] =
            "This " + key.replace(/_/g, "-") + " is select atleast one value.";
        }
      }

      // Check validate with email
      if (this.state[key] && rule === "email") {
        let lastAtPos = this.state.email.lastIndexOf("@");
        let lastDotPos = this.state.email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            this.state.email.indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            this.state.email.length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          errors[key] = key.replace(/_/g, "-") + " is not valid";
        }
      }

      // Check validate with password
      if (this.state[key] && rule === "password") {
        if (!this.state.password.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}/)) {
          formIsValid = false;
          errors[key] = "Password should at least be 6 characters long and should have at least one number, one lowercase, one uppercase letter & one special character.";
        }
      }

      // Check validate with alpha
      if (this.state[key] && rule === "alpha") {
        if (!this.state.name.match(/^[a-zA-Z]+$/)) {
          formIsValid = false;
          errors[key] = "Only letters";
        }
      }

      // Check validate with numeric
      if (this.state[key] && rule === "numeric") {
        if (!this.state.name.match(/^[0-9]+$/)) {
          formIsValid = false;
          errors[key] = "Only number";
        }
      }

      // Check validate with alpha-numeric
      if (this.state[key] && rule === "alpha-numeric") {
        if (
          !this.state.name.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/)
        ) {
          formIsValid = false;
          errors[key] = "Must be alpha and number is required.";
        }
      }
      return errors;
    });
  });

  return { errors, formIsValid };
};

exports.formValidation = formValidation;

// Toaster message function
var toaster = function (ToastsStore, type, message) {
  var toastMessage = "";
  if (typeof message == "string") {
    toastMessage = message;
  } else {
    for (var key in message) {
      if (message.hasOwnProperty(key)) {
        toastMessage = toastMessage + message[key];
        break;
      }
    }
  }

  return ToastsStore[type](toastMessage);
};

exports.toaster = toaster;
