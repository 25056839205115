import styled, { keyframes } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

const rotate = keyframes`
    from {
        transform: translate3d(-100%, 0 ,0)
    }
    to {
        transform: translate3d(200%, 0 ,0)
    }
`;

export const LoaderPage = styled.div`
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(35, 35, 35, 0.67);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
`;
export const LoaderBox = styled.div`
  background: var(--white);
  padding: 30px;
  border-radius: 8px;
`;
export const LoaderContent = styled.div`
  display: block;
  text-align: center;
  svg {
    max-height: 50px;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    color: var(--dark-blue-grey);
    margin: 10px 0;
  }
  .progressbar {
    height: 11px;
    border-radius: 7px;
    background-color: #e0e4ef;
    display: block;
    overflow: hidden;
    .progress-strip {
      display: block;
      width: 50%;
      height: 11px;
      border-radius: 7px;
      background-image: linear-gradient(
        to right,
        var(--deep-sky-blue) 4%,
        var(--green-blue) 98%
      );
      animation: ${rotate} 2s linear infinite;
    }
  }
`;

export const tableLoaderStyles = makeStyles({
  tableLoader: {
    height: 300,
    marginTop: 100,
    marginLeft: 100,
  },
  row: {
    display: "flex",
  },
  cell: {
    height: 30,
    width: 100,
    margin: 5,
  },
});
