import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  LoaderContent,
  LoaderPage,
  LoaderBox,
  tableLoaderStyles,
} from "./loaderStyled";
import classnames from "classnames";
import { LogoIcon } from "../../constants/icons";

export const OverlayLoader = (props) => {
  return (
    <LoaderPage className={classnames({ show: props.show })}>
      <LoaderBox>
        <LoaderContent>
          <LogoIcon />
          <p>Fetching data. Please wait.</p>
          <p>Fetched {props.scrappingPercent} %</p>
          <span className="progressbar">
            <span className="progress-strip"></span>
          </span>
        </LoaderContent>
      </LoaderBox>
    </LoaderPage>
  );
};

export const TableLoader = () => {
  const classes = tableLoaderStyles();
  return (
    <div className={classes.tableLoader}>
      <div className={classes.row}>
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
      </div>
      <div className={classes.row}>
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
      </div>
      <div className={classes.row}>
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
      </div>
      <div className={classes.row}>
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
        <Skeleton variant="rect" className={classes.cell} />
      </div>
    </div>
  );
};
