// const API = "https://bomapi.azurewebsites.net/api";
const API = process.env.REACT_APP_API_URL;
//
// POST Routes
export const API_URL = `${API}`;

export const SIGNUP = `${API}/users`;
export const CHANGE_PASSWORD = `${API}/users/resetPassword`;
export const LOGIN = `${API}/login`;
export const LOGOUT = `${API}/logout`;
export const FORGOT_PASSWORD = `${API}/users/forgotPassword`;
export const CUSTOMERS = `${API}/customers`;
export const BOM_PARSE = `${API}/bomProcessing`;
export const GET_QUOTE = `${API}/getQuotes`;
export const GET_BOMLINES_SCRAPPED_PERCENT = `${API}/getBomLinesScrappedPercent`;
export const ADD_USER = `${API}/users`;
export const SAVE_PARTS_REVS = `${API}/partRevisions`;
export const UPDATE_VENDOR_LIST = `${API}/distributors`;
export const STORE_RESULTANT_BOM = `${API}/storeResultantBom`;

// GET Routes
export const GET_USERS = `${API}/users`;
export const GET_USER = `${API}/users/:id`;
export const GET_ROLES = `${API}/roles`;
export const COLUMNS_LIST = `${API}/columns`;
export const COLUMN_ALIASES = `${API}/columnAliases`;
export const DISTRIBUTORS_LIST = `${API}/distributors`;
export const CUSTOMERS_LIST = `${API}/customers`;
export const PARTS_REVS_LIST = `${API}/partRevisions`;
export const BOM_HISTORY = `${API}/boms`;
export const GET_FILE = `${API}/file`;
export const SHARE_BOM = `${API}/shareBom`;

// UPDATE Routes
export const UPDATE_USER = `${API}/users/`;

// DELETE Routes
export const DELETE_USER = `${API}/user`;
