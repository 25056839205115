import React, { useState, useRef } from "react";
import classnames from "classnames";
import {
  TextGroup,
  TextLabel,
  InputGroup,
  TextField,
  InputGroupAppend,
  InputGroupText,
} from "../../Styled";
import { Button, Form } from "reactstrap";
import { formValidation, toaster } from "../../../function";
import { connect } from "react-redux";
import { changePassword } from "../../../actions";
import { ToastsStore } from "react-toasts";

const EditPassword = (props) => {
  const [errors, setErrors] = useState({});
  const eyeRef1 = useRef();
  const eyeRef2 = useRef();
  const eyeRef3 = useRef();
  const [showOldPassword, setShowOldPassword] = useState("password");
  const [showNewPassword, setShowNewPassword] = useState("password");
  const [showReTypedNewPassword, setShowReTypedNewPassword] = useState(
    "password"
  );
  const handleShowPassword = (imageRef, input) => {
    if (input === "1") {
      let password = showOldPassword === "password" ? "text" : "password";
      imageRef["current"].src =
        showOldPassword === "password"
          ? "./images/eye-off.png"
          : "./images/eye.png";
      setShowOldPassword(password);
    }
    if (input === "2") {
      let password = showNewPassword === "password" ? "text" : "password";
      imageRef["current"].src =
        showNewPassword === "password"
          ? "./images/eye-off.png"
          : "./images/eye.png";
      setShowNewPassword(password);
    }
    if (input === "3") {
      let password =
        showReTypedNewPassword === "password" ? "text" : "password";
      imageRef["current"].src =
        showReTypedNewPassword === "password"
          ? "./images/eye-off.png"
          : "./images/eye.png";
      setShowReTypedNewPassword(password);
    }
  };

  const requiredField = {
    old_password: ["required"],
    new_password: ["required"],
    retype_new_password: ["required"],
  };
  const updatePassword = async (e) => {
    e.preventDefault();
    const {
      old_password: { value: old_password },
      new_password: { value: new_password },
      retype_new_password: { value: retype_new_password },
    } = e.target;
    const validate = formValidation(requiredField, {
      old_password,
      new_password,
      retype_new_password,
    });
    if (validate.formIsValid) {
      if (new_password !== retype_new_password) {
        return setErrors({ retype_new_password: "Password doesn't match." });
      }
      const res = await props.changePassword(props.user.token, {
        old_password,
        new_password,
      });
      if (res.code >= 200 && res.code < 400) {
        props.close();
        toaster(ToastsStore, "success", res.message);
      } else {
        setErrors(validate.errors);
      }
    } else {
      setErrors(validate.errors);
    }
  };

  return (
    <Form className="w-100 white-form" onSubmit={updatePassword}>
      <TextGroup className={classnames({ error: errors.old_password })}>
        <TextLabel>Old Password</TextLabel>
        <InputGroup>
          <TextField
            type={showOldPassword}
            name="old_password"
            placeholder="Enter password"
          />
          <InputGroupAppend>
            <InputGroupText onClick={() => handleShowPassword(eyeRef1, "1")}>
              <img
                ref={eyeRef1}
                src="./images/eye.png"
                alt="eye-off"
                height="18"
              />
            </InputGroupText>
          </InputGroupAppend>
        </InputGroup>
        {errors.old_password ? (
          <span className="error-text">{errors.old_password}</span>
        ) : null}
      </TextGroup>
      <TextGroup className={classnames({ error: errors.new_password })}>
        <TextLabel>New Password</TextLabel>
        <InputGroup>
          <TextField
            type={showNewPassword}
            name="new_password"
            placeholder="Enter password"
          />
          <InputGroupAppend>
            <InputGroupText onClick={() => handleShowPassword(eyeRef2, "2")}>
              <img
                ref={eyeRef2}
                src="./images/eye.png"
                alt="eye-off"
                height="18"
              />
            </InputGroupText>
          </InputGroupAppend>
        </InputGroup>
        {errors.new_password ? (
          <span className="error-text">{errors.new_password}</span>
        ) : null}
      </TextGroup>
      <TextGroup className={classnames({ error: errors.retype_new_password })}>
        <TextLabel>Retype New Password</TextLabel>
        <InputGroup>
          <TextField
            type={showReTypedNewPassword}
            name="retype_new_password"
            placeholder="Enter password"
          />
          <InputGroupAppend>
            <InputGroupText onClick={() => handleShowPassword(eyeRef3, "3")}>
              <img
                ref={eyeRef3}
                src="./images/eye.png"
                alt="eye-off"
                height="18"
              />
            </InputGroupText>
          </InputGroupAppend>
        </InputGroup>
        {errors.retype_new_password ? (
          <span className="error-text">{errors.retype_new_password}</span>
        ) : null}
      </TextGroup>
      <TextGroup>
        <Button className="btn-blue px-5 w-auto" type="submit">
          Save
        </Button>
      </TextGroup>
    </Form>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps, { changePassword })(EditPassword);
