import React from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { EmailIcon } from "../../constants/icons";
import "./Button.css";

const EmailButton = (props) => {
  return (
    <Button className="bomButtons linkBtn email" onClick={props.onClick}>
      <EmailIcon className="icon" />
      <div className="text">Share via Email</div>
    </Button>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, {})(EmailButton);
