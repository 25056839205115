import { GET_CUSTOMERS, ADD_CUSTOMERS } from "../constants/actionTypes";

const customers = (state = [], action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return action.data.data;
    case ADD_CUSTOMERS:
      return state.concat(action.data);
    default:
      return state;
  }
};

export default customers;
