import {
  SET_COLUMN_ALIASES,
  UNSET_COLUMN_ALIASES,
} from "../constants/actionTypes";

const columnAliases = (state = [], action) => {
  switch (action.type) {
    case SET_COLUMN_ALIASES:
      return action.data.data;
    case UNSET_COLUMN_ALIASES:
      return {};
    default:
      return state;
  }
};

export default columnAliases;
